import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
Vue.use(Vuex)
import { Message,MessageBox } from 'element-ui';
export default new Vuex.Store({
  state: {
    nickname: '',
    avatar: ''
  },
  mutations: {
    perUserInfo(state, step) {
      // console.log('sssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',step);
      state.nickname = step.nickname;
      state.avatar = step.avatar;
    }
  },
  actions: {
    userinfo(context, step) {
      // console.log(step);
      context.commit('perUserInfo', step)
    },
    logout({ state }, step) { // 退出登录
      let token = localStorage.getItem('token')
      if(!token) return
      MessageBox.confirm('此操作将会退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.removeItem('token')
        for (const key in state) {
          state[key] = ''
        }
        Message({
          type: 'success',
          message: '退出登录'
        });
        window.location.reload()

      }).catch(() => {
    
      });
    },
  },
  modules: {
  }
})
