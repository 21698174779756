<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>

      <div class="question_list_wrap box_sizing">

        <div
          class="question_type_box box_sizing flex_box flex_align_center flex_justify_between"
        >
          <navtitle :ischoose="3" :page="page" :pinyin="pinyin" :title="title" :topic_id="topic_id"></navtitle>
        </div>
        <div v-if="list.length>0" class="question_list_box">

          <div
            class="question_list box_sizing flex_box flex_align_center flex_justify_between"
            v-for="(item, index) in list"
            :key="index"

          >
            <div
              class="question_list_info box_sizing flex_box flex_align_center"
              @click="jumpDetail(item.id)"
            >
              <div class="question_list_tihao">{{ item.topic_type.short_name }}</div>
              <div class="" v-if="item.word_data == null">{{ item.topic.name }}</div>
              <div class="" v-else>{{ item.topic.name }}（一句话模式）</div>
              <div class="" style="margin-left: 20px" >{{ item.createtime }}</div>
            </div>
            <div class="box_sizing flex_box flex_align_center justify_end">
              <div class="question_lian_cishu"  @click="copyToH5(item)">
                微信分享
              </div>
              <div class="question_lian_cishu" v-if="item.submit==0" @click="toSubmit(item.id)">
                申请教师批改
              </div>
              <div v-if="item.submit==1 && item.content == null" class="question_lian_cishu"  @click="copyDomain(item.id)">
<!--                <img-->
<!--                  src="../assets/images/type_fenxiang.png"-->
<!--                  class="topic_type_info_fenxiang"-->
<!--                  @click="copyDomain(item.id)"-->
<!--                  alt=""-->
<!--                />-->
复制批改链接
              </div>

              <div class="question_lian_cishu" v-if="item.submit==1 && item.content !== null" @click="showPigai(item)">
                查看批改
              </div>

            </div>
          </div>


        </div>
        <div v-else style="height: 400px;width: 100%;display: flex;align-items: center;justify-content: center;flex-direction: column">
          <img src="@/assets/image/empty.png" style="width: 120px;height: 120px">
          <span style="color: #c4cad3">暂无数据</span>
        </div>
        <div style="display: flex;  justify-content: flex-end;">
          <el-pagination
            :page-size="currentPage.rows"
            layout="prev, pager, next"
            :total="currentPage.total"
            :current-page="currentPage.page"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <footers></footers>

    <div class="model" v-if="status">
      <div class="modelContent box_sizing">
        <div class="title"> 老师批改</div>
        <div class="zuoda box_sizing">
          <div v-if="detail.type ===2 && detail.content !== ''">
            <div class="zuodacont" style="height: 200px !important;" v-html="detail.content"></div>
            <div class="cancel" @click="status = false">
              <img src="../assets/images/login_close.png" alt="" />
            </div>
          </div>


          <div v-if="detail.type ===1 && detail.content !== ''">
            <div class="my_padding box_sizing">
              <div class="audio_inp flex_box">
                <div class="audio_left flex_box">
                  <img @click="playAudioTopic" v-if="playStatus" :src="zantingUrl" />
                  <img @click="playAudioTopic" v-else :src="playUrl" />
                  <div class="audio_l_right box_sizing flex_box flex_align_center">
                    <div class="audio_lr_jindu flex_box">
                      <div class="topic_type1_luyin my_xiahuaxian" @touchstart="touchstart()" @touchmove="touchmove()" @touchend="touchend()">
                        <div class="topic_type1_luyin1" :style="{ width: scrollerwidth }"></div>
                      </div>
                      <div class="my_time box_sizing">{{ timeLength }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cancel" @click="status = false">
              <img src="../assets/images/login_close.png" alt="" />
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>

</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
import "@/css/questionList.css";
import Navtitle from "@/components/navtitle.vue";

var _this;
export default {
  components: {
    Navtitle,
    headers,
    footers,
    left,
    right,
    scrollpage,
  },
  data() {
    return {
      topic_id:undefined,
      title:          "",
      pinyin:"",
      page:"",

      playStatus: false,
      zantingUrl: require("../assets/images/zanting_icon@2x.png"),
      playUrl:    require("../assets/images/play.png"),
      detail:     {},
      timeLength: "00:00:00",
      timenum:    "", //当前剩余时间
      timeall:    "", //音频总长
      LastTime:   0, // 播放时上次的播放进度
      LastWidth:  0, // 播放时上次进度条的长度
      timer:      null,

      status:         false,
      currentPage:    {
        page:  1,
        rows:  10,
        total: 0
      },
      is_sort:        false,
      sort_num:       1, // 1为正序,-1位倒序
      id:             "",
      list:           [],
      jiangjieId:     0,
      scStatus:       false,
      jiangjieList:   [
        {
          id:   0,
          name: "全部",
        },
        {
          id:   1,
          name: "有讲解",
        },
        {
          id:   2,
          name: "无讲解",
        },
      ],
      lianxiId:       1,
      lianxiList:     [
        {
          id:   1,
          name: "可练习",
        },
        {
          id:   2,
          name: "不可练习",
        },
      ],
      pinlvId:        0,
      pinlvList:      [
        {
          id:   0,
          name: "全部",
        },
        {
          id:   1,
          name: "高频",
        },
        {
          id:   2,
          name: "新题",
        },
        {
          id:   3,
          name: "旧题",
        },
      ],
      collectId:      undefined,
      collectList:    [
        {
          id:   0,
          name: "全部",
        },
        {
          id:   1,
          name: "未收藏",
        },
      ],
      count:          0,
      exercise_count: 0,
      allList:        [],
      audio:          null,
    };
  },
  created() {
    _this = this;
    _this.getParams();
    // _this.audio.pause()
    _this.audio = new Audio();
  },
  computed: {
    // 进度条
    scrollerwidth: function () {
      // console.log(_this.timenum);
      // 进度条长度计算，当前时间除以总时间乘以百分比
      let a = _this.timenum; //当前剩余时间
      let b = _this.timeall; //音频总长
      var c = ((b - a) / b) * 100 + "%";

      // 保存进度
      _this.LastTime = a;
      // console.log(_this.LastTime);
      // _this.LastWidth = c;
      // console.log(c,'进度');
      // console.log(_this.timenum,'时间');
      return c;
    },
  },
  methods:  {
    copyToH5(item){

    // console.log('当前的domain', item);return
      let tips = this.pinyin+" "+item.topic.number+" "+" PTE易王炸AI评分 ";
      this.$copyText(tips+" "+window.location.origin+"/H5Detail?exericise_id="+item.id+"&topic_id="+ this.topic_id+"&pinyin="+this.pinyin+"&title="+encodeURIComponent(this.title)+"&page="+this.page);
      this.$message.success("地址复制成功")
    },
    playAudioTopic() {
      if (_this.playStatus) {
        //暂停
        _this.audio.pause();
        _this.playStatus = false;
        _this.timeupdate1(_this.timeall - _this.LastTime);
        clearInterval(_this.timer);
        console.log('最后的时间-暂停', _this.LastTime)
      } else {
        //播放
        console.log('最后的时间-开始', _this.LastTime)
        let playPromise = null
        let second = _this.LastTime > 0 ? _this.LastTime : _this.timeall;

        playPromise = _this.audio.play();
        _this.playStatus = true;
        if (playPromise) {
          playPromise
            .then(() => {
              // 音频加载成功
              // 音频的播放需要耗时
              _this.timer = setInterval(() => {
                console.log(second);
                second--;
                _this.timeLength = _this.formateSeconds(second);
                if (second <= 0) {
                  // 播放结束
                  _this.audio.load();
                  // 重置保存上次播放的时间
                  _this.LastTime = 0;
                  // _this.timeLength = _this.timers;
                  _this.playStatus = false;
                  _this.bofangYin = true;
                  clearInterval(_this.timer)
                }
              }, 1000);
            })
            .catch((e) => {
              // 音频加载失败
              // console.error(e);
            });
        }
      }
    },
    timeupdate1(time) {
      console.log(time, '播放位置')
      // let audio = this.$refs.audio;
      // _this.audio.currentTime = time;
    },
    formateSeconds(endTime) {
      _this.timenum = endTime; //????
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime >= 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min >= 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },
    showPigai(item) {
      if (item.content !== null) {
        this.status = true
        this.detail = item
        if (item.type === 1) {
          _this.audio.src = _this.$global.uploadUrl + _this.detail.content;
          _this.audio.addEventListener('canplaythrough', () => {
            _this.timeall = Math.round(_this.audio.duration);
            _this.timeLength = _this.formateSeconds(_this.timeall);
            _this.timers = _this.formateSeconds(_this.timeall);
            //
            // if (_this.detail.topic_type_id == 2) {
            //   _this.timeLen = 15;
            // } else if (_this.detail.topic_type_id == 5) {
            //   _this.timeLen = 10;
            // }
          })
        }
      }
    },
    pageChange(e) {
      this.currentPage.page = e
      console.log('当前额', _this.currentPage)

      _this.getList()
    },
    // 跳转题目详情
    jumpDetail(id) {

      console.log('跳转详情')
        this.$router.push({
          path:"/myDetail?exericise_id="+id+"&topic_id="+ this.topic_id+"&pinyin="+this.pinyin+"&title="+this.title+"&page="+this.page
        })
    },

    //选择收藏哪个
    collowCilck(i) {
      let list = _this.list;
      list.forEach((item, index) => {
        if (i == index && !item.show) {
          item.show = true;
        } else {
          item.show = false;
        }
      });

      _this.list = list;
    },

    // 收藏题目
    collectFun(i, obj, type) {
      // console.log(i)
      let list = _this.list;
      let params = {
        topic_id: obj.id,
        type:     type,
      };

      if (localStorage.getItem("token")) {
        _this.$axios
          .post("exercise.topic_collect/collect", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              list.forEach((item, index) => {
                if (i == index) {
                  item.topic_collect = type;
                  item.show = false;
                }
              });
              _this.list = list;
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },
    toSubmit(e) {
      let url = 'exercise.topic_exercise/submit'
      let params = {
        topic_exercise_id: e
      }

      _this.$axios.post(url, params).then(res => {
        console.log('当前的返回', res)
        if (res.code == 1) {
          _this.$message({
            showClose: true,
            message:   '申请成功',
            type:      'success'
          });
          location.reload()
        }else {
          _this.$message.error(res.msg)
        }


      }).catch(err => {
        _this.$message.error(err.msg)
      })
    },


    // 获取练习列表
    getList() {
      var list = [];
      var params = {
        // topic_type_id: _this.id,
        page: _this.currentPage.page,
        rows: _this.currentPage.rows
      };
      if (_this.topic_id !== undefined){
        params.topic_id = _this.topic_id
      }
      _this.$axios
        .post("exercise.topic_exercise/getExerciseList", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            res.data.data.forEach((item, index) => {
              list.push(item);
            });
            _this.allList = res.data.data;
            _this.list = list;
            _this.currentPage.page = res.data.current_page;
            _this.currentPage.total = res.data.total;
            _this.count = res.data.total;
            _this.exercise_count = res.data.total;
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取传递过来的参数
    getParams() {
      _this.topic_id = _this.$route.query.id;
      _this.title = _this.$route.query.title;
      _this.pinyin = _this.$route.query.pinyin;
      _this.page = _this.$route.query.page;

      _this.getList();
    },

    // 修改联系状态
    lianxiChange(e) {
      console.log(e);
      let indexLx = e.target.options.selectedIndex;
      _this.lianxiId = _this.lianxiList[indexLx].id;
      console.log(_this.lianxiId, "修改联系状态");
      _this.screenList();
    },

    // 修改频率状态
    pinlvChange(e) {
      console.log(e);
      let indexPl = e.target.options.selectedIndex;
      _this.pinlvId = _this.pinlvList[indexPl].id;
      console.log(_this.pinlvId);
      _this.screenList();
    },
    // 收藏筛选
    screenCollect(index) {
      _this.collectId = index;
      _this.scStatus = false;
      _this.screenList();
    },
    // 手动筛选
    // /**
    //  * @param {number} type [1:可练,2:频率,3:收藏,4,讲解]
    //  * @param {number} value [对应type的转态值]
    //  */
    screenList() {
      let type_obj = {
        exercise_status: _this.lianxiId, //练习状态 1=可练习2=不可练习
        high_status:     _this.pinlvId == 1 ? 1 : undefined, //高频开关 0=关1=开
        time_status:     _this.pinlvId < 2 ? undefined : _this.pinlvId == 2 ? 1 : 0, //0=旧题1=新题
        topic_collect:   _this.collectId, //收藏 之前添加传的
        topic_lecture_id:
                         _this.jiangjieId == 0 ? undefined : _this.jiangjieId == 1 ? 1 : 0, //名师讲解id 0=没有讲解
      };
      let all_list = _this.allList;
      let list = [];
      for (const index in all_list) {
        let istrue = true;
        for (const key in type_obj) {
          if (key == "topic_collect" && all_list[index][key] == null)
            all_list[index][key] = 1;
          if (key == "topic_lecture_id" && all_list[index][key] > 0)
            all_list[index][key] = 1;
          if (
            type_obj[key] != undefined &&
            all_list[index][key] != type_obj[key]
          )
            istrue = false;
        }
        if (istrue) list.push(all_list[index]);
      }

      _this.list = list;
    },

    // 切换讲解
    jiangjieChange(e) {
      _this.screenList();
    },
    copyDomain(id) {
      _this.$copyText(_this.$global.baseUrl + "&id=" +id)
      this.$message.success("地址复制成功")
    },
    sortList() {
      const compare = (obj1, obj2) => {
        let val1 = obj1.number;
        let val2 = obj2.number;
        if (val1 < val2) {
          return -1 * _this.sort_num;
        } else if (val1 > val2) {
          return 1 * _this.sort_num;
        } else {
          return 0;
        }
      };
      console.log(_this.sort_num)
      if (_this.sort_num === 1) {
        _this.sort_num = -1;
      } else {
        _this.sort_num = 1;
      }
      _this.list = _this.list.sort(compare);
    },
  },
};
</script>


<style scoped>
.clear_img {
  position: absolute;
  left: 60px;
  top: 5px;
  width: 16px;
  height: 16px;
}

.pointer {
  cursor: pointer;
  user-select: none;
}

.color_black {
  color: black !important;
}

.question_lian_cishu {
  padding: 5px 14px;
  margin-right: 12px;
  background: #FFFFFF;
  border-radius: 4px;
  font-size: 12px;
  color: #f6704b;
  border: 1px solid #f6704b;
}
</style>
