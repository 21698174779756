<template>
    <div class="container">
        <headers></headers>
        <div class="w result_wrap box_sizing flex_box flex_diection flex_align_center">
            <div class="result_image">
                <img src="../assets/images/chenggong.png" alt="">
            </div>
            <div class="pay_result_title">支付成功</div>

            <div class="pay_result_center box_sizing flex_box flex_align_center flex_justify_center">
                <div class="pay_result_wx">
                    <img src="../assets/images/wx_img.png" alt="">
                </div>
                <div class="pay_result_list_box">
                    <div class="result_center_list">恭喜你，支付成功</div>
                    <div class="result_center_list">请添加客服微信，加入学习小组。</div>
                    <div class="result_center_list">添加时请备注购买的课程名称</div>
                </div>
            </div>

            <div class="result_btn_box box_sizing flex_box flex_align_center flex_justify_center">
                <div class="pay_result_btn" @click="goBack">已添加</div>
                <div class="result_center_btn" @click="goBack">暂不添加</div>
            </div>
            
            
        </div>

        <footers></footers>
    </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";

import "@/css/couse_detail.css";
export default {
    components: {
        headers,
        footers,
    },

    methods: {
        goBack() {
            this.$router.back();//返回上一层
        }
    }
}
</script>

<style>
.w {
    padding-left: 0;
    margin-bottom: 40px!important;
}

</style>











