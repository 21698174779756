<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>

      <div class="banner_detail box_sizing" v-html="content"></div>
    </div>

    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
var _this;
export default {
  components: {
    headers,
    footers,
    left,
    right,
    scrollpage,
  },
  data() {
    return {
      content: "",
      id: "",
    };
  },
  created() {
    _this = this;
    _this.getParams();
  },
  mounted() {},
  methods: {
    // 获取传递过来的参数
    getParams() {
      _this.id = _this.$route.query.id;
      _this.getDetail();
    },

    // 获取banner详情
    getDetail() {
      var d = {
        id: _this.id,
      };
      _this.$axios
        .post("banner/getInfo", d)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {

              let  str = res.data.replace(/<img[^>]*>/gi, function (match, capture) {
                return match.replace(/style\s*?=\s*?([‘"])[\s\S]*?\1/ig, 'style="width:100%;height:auto;margin:0 auto; display:block;"') // 替换style
            })
            // console.log('htmlstr', str)
            _this.content = str;

          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

   
  },
};
</script>


<style>
.banner_detail {
  width: 100%;
  padding: 32px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 1);
  /* text-align: center; */
}
</style>
