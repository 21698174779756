import headers from '@/components/headers'
import footers from '@/components/footders'
import left from '@/components/left'
import right from '@/components/right'
import scrollpage from '@/components/scrollpage'
export default {
  install(Vue) {
    // 基于Vue做任何事情（注册全局组件，指令，过滤器，挂载原型函数）
    Vue.component(headers.name, headers)
    Vue.component(footers.name, footers)
    Vue.component(left.name, left)
    Vue.component(right.name, right)
    Vue.component(scrollpage.name, scrollpage)
  }
}