<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>
  </div>
</template>

<script>
import headers from "@/components/headers";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
// import recording from "@/js/recorder.js";
var _this;
export default {
  components: {
    headers,
    right,
    scrollpage,
  },
  data() {
    return {
    };
  },
  created() {
    _this = this;
  },
  destroyed() {
    console.log("1111111111111111111111");
  },
  methods: {

  },
};
</script>


<style>
.icon {
  cursor: pointer;
}
</style>
