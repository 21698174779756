<template>
    <div class="container">
        <div class="scroll_wrap">
            <div class="scroll_list">
                <!-- <img src="../assets/images" alt=""> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'scrollpage',
    data() {
        return {

        }
    }
}
</script>


<style>
    .scroll_wrap {
        position: fixed;
        right: 36px;
        bottom: 200px;
    }

    .scroll_list {
        width: 48px;
        height: 48px;
        margin-bottom: 14px;
    }

</style>











