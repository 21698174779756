var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('headers'),_c('right'),_c('scrollpage'),_c('div',{staticClass:"w box_sizing width_box my_wrap"},[_c('div',{staticClass:"left_position"},[_c('left')],1),_c('div',{staticClass:"my_practice_wrap box_sizing"},[_c('div',{staticClass:"practice_top box_sizing flex_box flex_justify_between flex_align_center"},[_c('div',{staticClass:"practice_list box_sizing flex_box flex_justify_between flex_align_center",on:{"click":function($event){return _vm.typeChange(0)}}},[_c('div',{staticClass:"practice_list_left flex_box flex_justify_center flex_align_center",class:[_vm.type == 0 ? 'now' : '']},[_c('img',{staticClass:"practice_list_left_icon",attrs:{"src":_vm.type == 0
                  ? require('../assets/images/icon002.png')
                  : require('../assets/images/icon001.png'),"alt":""}})]),_c('div',{staticClass:"practice_list_right",class:[_vm.type == 0 ? 'now' : '']},[_vm._v(" 全部 ")])]),_c('div',{staticClass:"practice_list box_sizing flex_box flex_justify_between flex_align_center",on:{"click":function($event){return _vm.typeChange(1)}}},[_c('div',{staticClass:"practice_list_left flex_box flex_justify_center flex_align_center",class:[_vm.type == 1 ? 'now' : '']},[_c('img',{staticClass:"practice_list_left_icon",attrs:{"src":_vm.type == 1
                  ? require('../assets/images/icon004.png')
                  : require('../assets/images/icon003.png'),"alt":""}})]),_c('div',{staticClass:"practice_list_right",class:[_vm.type == 1 ? 'now' : '']},[_vm._v(" 口语 ")])]),_c('div',{staticClass:"practice_list box_sizing flex_box flex_justify_between flex_align_center",on:{"click":function($event){return _vm.typeChange(2)}}},[_c('div',{staticClass:"practice_list_left flex_box flex_justify_center flex_align_center",class:[_vm.type == 2 ? 'now' : '']},[_c('img',{staticClass:"practice_list_left_icon",attrs:{"src":_vm.type == 2 ? require('../assets/images/icon006.png'): require('../assets/images/icon005.png'),"alt":""}})]),_c('div',{staticClass:"practice_list_right",class:[_vm.type == 2 ? 'now' : '']},[_vm._v(" 写作 ")])]),_c('div',{staticClass:"practice_list box_sizing flex_box flex_justify_between flex_align_center",on:{"click":function($event){return _vm.typeChange(3)}}},[_c('div',{staticClass:"practice_list_left flex_box flex_justify_center flex_align_center",class:[_vm.type == 3 ? 'now' : '']},[_c('img',{staticClass:"practice_list_left_icon",attrs:{"src":_vm.type == 3
                  ? require('../assets/images/icon008.png')
                  : require('../assets/images/icon007.png'),"alt":""}})]),_c('div',{staticClass:"practice_list_right",class:[_vm.type == 3 ? 'now' : '']},[_vm._v(" 阅读 ")])]),_c('div',{staticClass:"practice_list box_sizing flex_box flex_justify_between flex_align_center",on:{"click":function($event){return _vm.typeChange(4)}}},[_c('div',{staticClass:"practice_list_left flex_box flex_justify_center flex_align_center",class:[_vm.type == 4 ? 'now' : '']},[_c('img',{staticClass:"practice_list_left_icon",attrs:{"src":_vm.type == 4
                  ? require('../assets/images/icon010.png')
                  : require('../assets/images/icon009.png'),"alt":""}})]),_c('div',{staticClass:"practice_list_right",class:[_vm.type == 4 ? 'now' : '']},[_vm._v(" 听力 ")])])]),_c('div',{staticClass:"practice_box box_sizing"},_vm._l((_vm.list),function(item,index){return _c('div',{key:'list' + index,staticClass:"practice_list_wrap"},[(item.child.length != 0)?_c('div',{staticClass:"practice_list_box_top"},[_c('div',{staticClass:"practice_list_top_en"},[_vm._v(_vm._s(item.en_name))]),_c('div',{staticClass:"practice_list_top_title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"practice_list_top_line"})]):_vm._e(),_c('div',{staticClass:"practice_list_box box_sizing flex_box flex-warp"},_vm._l((item.child),function(items,indexs){return _c('div',{key:'items' + indexs,staticClass:"practice_item box_sizing",style:('background:url(' +
                item.bg +
                ') no-repeat left top;background-size: 100% 100%;-webkit-background-size: 100% 100%;'),on:{"click":function($event){return _vm.jumpList(items)}}},[_c('div',{staticClass:"practic_item_top box_sizing flex_box flex_align_center"},[_c('div',{staticClass:"practic_item_label box_sizing flex_box flex_justify_center flex_align_center"},[_vm._v(" "+_vm._s(items.short_name)+" ")]),_c('div',{staticClass:"practic_item_title"},[_vm._v(_vm._s(items.name))])]),_c('div',{staticClass:"practice_item_list"},[_vm._v(" 题 量： "+_vm._s(items.topic_count)+" ")]),_c('div',{staticClass:"practice_item_list"},[_vm._v(" 学习次数： "+_vm._s(items.topic_exercise_count)+" ")]),_c('div',{staticClass:"practice_item_list"},[_vm._v(" 考试分值： "+_vm._s(items.score)+" ")])])}),0)])}),0)])]),_c('footers')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }