<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>

      <div class="my_practice_wrap box_sizing">
        <div class="practice_top box_sizing flex_box flex_justify_between flex_align_center">
          <div class="practice_list box_sizing flex_box flex_justify_between flex_align_center" @click="typeChange(0)">
            <div class="practice_list_left flex_box flex_justify_center flex_align_center" :class="[type == 0 ? 'now' : '']">
              <img :src="
                  type == 0
                    ? require('../assets/images/icon002.png')
                    : require('../assets/images/icon001.png')
                " class="practice_list_left_icon" alt="" />
            </div>
            <div class="practice_list_right" :class="[type == 0 ? 'now' : '']">
              全部
            </div>
          </div>
          <div class="practice_list box_sizing flex_box flex_justify_between flex_align_center" @click="typeChange(1)">
            <div class="practice_list_left flex_box flex_justify_center flex_align_center" :class="[type == 1 ? 'now' : '']">
              <img :src="
                  type == 1
                    ? require('../assets/images/icon004.png')
                    : require('../assets/images/icon003.png')
                " class="practice_list_left_icon" alt="" />
            </div>
            <div class="practice_list_right" :class="[type == 1 ? 'now' : '']">
              口语
            </div>
          </div>
          <div class="practice_list box_sizing flex_box flex_justify_between flex_align_center" @click="typeChange(2)">
            <div class="practice_list_left flex_box flex_justify_center flex_align_center" :class="[type == 2 ? 'now' : '']">
              <img :src=" type == 2 ? require('../assets/images/icon006.png'): require('../assets/images/icon005.png')" class="practice_list_left_icon" alt="" />
            </div>
            <div class="practice_list_right" :class="[type == 2 ? 'now' : '']">
              写作
            </div>
          </div>
          <div class="practice_list box_sizing flex_box flex_justify_between flex_align_center" @click="typeChange(3)">
            <div class="practice_list_left flex_box flex_justify_center flex_align_center" :class="[type == 3 ? 'now' : '']">
              <img :src="
                  type == 3
                    ? require('../assets/images/icon008.png')
                    : require('../assets/images/icon007.png')
                " class="practice_list_left_icon" alt="" />
            </div>
            <div class="practice_list_right" :class="[type == 3 ? 'now' : '']">
              阅读
            </div>
          </div>
          <div class="practice_list box_sizing flex_box flex_justify_between flex_align_center" @click="typeChange(4)">
            <div class="practice_list_left flex_box flex_justify_center flex_align_center" :class="[type == 4 ? 'now' : '']">
              <img :src="
                  type == 4
                    ? require('../assets/images/icon010.png')
                    : require('../assets/images/icon009.png')
                " class="practice_list_left_icon" alt="" />
            </div>
            <div class="practice_list_right" :class="[type == 4 ? 'now' : '']">
              听力
            </div>
          </div>


        </div>

        <div class="practice_box box_sizing">
          <div class="practice_list_wrap" v-for="(item, index) in list" :key="'list' + index">
            <div class="practice_list_box_top" v-if="item.child.length != 0">
              <div class="practice_list_top_en">{{ item.en_name }}</div>
              <div class="practice_list_top_title">{{ item.name }}</div>
              <div class="practice_list_top_line"></div>
            </div>
            <div class="practice_list_box box_sizing flex_box flex-warp">
              <div class="practice_item box_sizing" v-for="(items, indexs) in item.child" :key="'items' + indexs" @click="jumpList(items)" :style="
                  'background:url(' +
                  item.bg +
                  ') no-repeat left top;background-size: 100% 100%;-webkit-background-size: 100% 100%;'
                ">
                <div class="practic_item_top box_sizing flex_box flex_align_center">
                  <div class="practic_item_label box_sizing flex_box flex_justify_center flex_align_center">
                    {{ items.short_name }}
                  </div>
                  <div class="practic_item_title">{{ items.name }}</div>
                </div>
                <div class="practice_item_list">
                  题 量： {{ items.topic_count }}
                </div>
                <div class="practice_item_list">
                  学习次数： {{ items.topic_exercise_count }}
                </div>
                <div class="practice_item_list">
                  考试分值： {{ items.score }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
import "@/css/practice.css";

var _this;
export default {
  components: {
    headers,
    footers,
    left,
    right,
    scrollpage,
  },
  data() {
    return {
      type: 0,
      list: [], //题型列表
      alltype: [], //分类列表
    };
  },
  created() {
    _this = this;
    _this.getlist();
    // _this.getTypeList();
  },
  methods: {
    getlist() {
      _this = this;
      _this.type = _this.$route.query.id || 0;
      _this.typelist();
      console.log(_this.$route.query.id, 'id')
    },

    // typechange  切换type
    typeChange(type) {
      _this.type = type;
      _this.typelist();
    },
    // 获取题型列表
    typelist() {
      console.log(this.type, 'type')
      var params = {
        topic_cate_id: this.type,
      };
      _this.$axios
        .post("exercise.topic_type/getTypeList", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            res.data.forEach((item, index) => {
              let x = index * 1 + 1;
              item.bg = require("../assets/images/bg_img0" + x + ".png");
            });
            _this.list = res.data;
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取题型列表
    // getTypeList() {
    //   _this.$axios
    //     .post("exercise.topic_type/getTypeList", {})
    //     .then((res) => {
    //       console.log(res);
    //       if (res.code == 1) {
    //         res.data.forEach((item, index) => {
    //           let x = index * 1 + 1;
    //           item.bg = require("../assets/images/bg_img0" + x + ".png");
    //         });
    //         _this.list = res.data;
    //       } else {
    //         _this.$message.error(res.msg);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // 跳转练习题列表
    jumpList(obj) {
      // _this.$router.push({
      //     path: '/questionList?id=' + id
      // })
      _this.$router.push({
        path: "/questionList",
        query: {
          id: obj.id,
          name: obj.name,
        },
      });
    },
  },
};
</script>


<style></style>


