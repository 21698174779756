<template>
    <div class="container">
        <headers></headers>
        <div class="w result_wrap box_sizing flex_box flex_diection flex_align_center">
            <div class="result_image">
                <img src="../assets/images/fail.png" alt="">
            </div>
            <div class="pay_result_title">支付未成功</div>
            <div class="pay_result_txt">订单支付失败，请核实支付情况</div>
            <div class="pay_result_txt now">如有疑问，请致电客服电话：13812388988</div>
            <div class="pay_result_btn" @click="goBack">返回</div>
        </div>

        <footers></footers>
    </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";

import "@/css/couse_detail.css";
export default {
    components: {
        headers,
        footers,
    },

    methods: {
        goBack() {
            this.$router.back();//返回上一层
        }
    }
}
</script>

<style>
.w {
    padding-left: 0;
    margin-bottom: 40px!important;
}

</style>











