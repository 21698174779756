<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>

      <div class="question_list_wrap box_sizing">
        <div
          class="question_type_box box_sizing flex_box flex_align_center flex_justify_between"
        >
          <div class="question_list_left flex_1 flex_box flex_align_center">
            <div class="question_type_name">{{ title }}</div>
            <div class="question_type_label">总题数：{{ count }}</div>
            <div class="question_type_label">已练习：{{ exercise_count }}</div>
            <div>
              <input type="text" v-model="text" @focus="focusSearch1" @blur="blurSearch1" placeholder="输入关键词搜索" 
              style="width: 300px;margin: 5px 0;padding-left: 10px;height: 34px;border: 1.5px solid rgba(246, 112, 75, 1);border-radius: 20px;"/>
            </div>
            <div class="search_icon box_sizing" @click="jumpSearch1" style="margin-left: -30px;">
              <img src="../assets/images/search_icon.png" alt="" />
            </div>
          </div>
          <!-- <img
            src="../assets/images/guanbi.png"
            class="question_type_right"
            alt="返回上一页"
          /> -->
        </div>

        <div class="question_flex_topbox box_sizing flex_box">
          <div class="question_flex_toplist flex_1">
            <div class="question_toplist_top">可练</div>
            <div
              class="question_toplist_select box_sizing flex_box flex_align_center flex_justify_between"
            >
              <select name="" id="" v-model="lianxiId" @change="lianxiChange">
                <option
                  :value="item.id"
                  v-for="(item, index) in lianxiList"
                  :key="'lianxi' + index"
                >
                  {{ item.name }}
                </option>
              </select>
              <!-- <div class="question_toplist_sanjiao"></div> -->
            </div>
          </div>
          <div class="question_flex_toplist flex_1">
            <div class="question_toplist_top">频率</div>
            <div
              class="question_toplist_select box_sizing flex_box flex_align_center flex_justify_between"
            >
              <select name="" id="" v-model="pinlvId" @change="pinlvChange">
                <option
                  :value="item.id"
                  v-for="(item, index) in pinlvList"
                  :key="'pinlv' + index"
                >
                  {{ item.name }}
                </option>
              </select>
              <!-- <div class="question_toplist_sanjiao"></div> -->
            </div>
          </div>
          <div class="question_flex_toplist flex_1">
            <div class="question_toplist_top">收藏</div>
            <div
              @click="scStatus = !scStatus"
              class="question_toplist_select box_sizing flex_box flex_align_center flex_justify_between"
            >
              <div class="question_shaixuan_left" style="position: relative">
                <!-- <img src="../assets/images/shoucang1.png" alt=""> -->
                <img
                  v-if="collectId != undefined"
                  :src="require(`../assets/images/shoucang${collectId}.png`)"
                  alt=""
                />
                <img
                  v-if="collectId != undefined"
                  src="../assets/images/guanbi.png"
                  alt=""
                  class="clear_img"
                  @click.stop="screenCollect(undefined)"
                />
              </div>
              <div class="question_toplist_sanjiao"></div>
              <div
                class="question_list_sc_box question_list_shoucang_shaixuan"
                v-if="scStatus"
              >
                <div
                  @click.stop="screenCollect(index + 1)"
                  class="question_list_sc_list box_sizing"
                  v-for="(item, index) in 5"
                  :key="index"
                >
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      :src="
                        require(`../assets/images/shoucang${index + 1}.png`)
                      "
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="question_flex_toplist flex_1">
            <div class="question_toplist_top">讲解</div>
            <div
              class="question_toplist_select box_sizing flex_box flex_align_center flex_justify_between"
            >
              <select
                name=""
                id=""
                v-model="jiangjieId"
                @change="jiangjieChange"
              >
                <option
                  :value="item.id"
                  v-for="(item, index) in jiangjieList"
                  :key="'jiangjie' + index"
                >
                  {{ item.name }}
                </option>
              </select>
              <!-- <div class="question_toplist_sanjiao"></div> -->
            </div>
          </div>
          <div class="question_flex_toplist flex_1">
            <div class="question_toplist_top">标签</div>
            <div
              class="question_toplist_select box_sizing flex_box flex_align_center flex_justify_between"
            >
              <select
                name=""
                id=""
                v-model="tag_id"
                @change="tagChange"
              >
                <option
                  :value="item.id"
                  v-for="(item, index) in tagList"
                  :key="'tag' + index"
                >
                  {{ item.name }}
                </option>
              </select>
              <!-- <div class="question_toplist_sanjiao"></div> -->
            </div>
          </div>
        </div>

        <!-- <div class="question_status_box">
          <div
            class="box_sizing flex_box flex_align_center question_status_box flex_justify_between"
          > -->
            <!-- <div class="box_sizing flex_box flex_align_center"> -->
              <!-- <div
                class="question_status_list box_sizing flex_box flex_align_center flex_justify_center hand_change"
                @click="toSearch('createtime')"
                :class="orderType === 'createtime'?'question_tihao_type_':''"

              >
                按新增
              </div>
              <div
                class="question_status_list box_sizing flex_box flex_align_center flex_justify_center hand_change"
                @click="toSearch('update')"
                :class="orderType === 'update'?'question_tihao_type_':''"

              >
                按更新
              </div> -->
              <!--              <div-->
              <!--                class="question_status_list box_sizing flex_box flex_align_center flex_justify_center hand_change"-->
              <!--                @click="toSearch('study_count')"-->
              <!--                :class="orderType === 'study_count'?'question_tihao_type':''"-->

              <!--              >-->
              <!--                按学习次数-->
              <!--              </div>-->
            <!-- </div> -->
            <!-- <div
              class="box_sizing flex_box flex_align_center justify_end pointer"
              @click="sortList"
            >
              <div class="question_tihao_type">按题号</div>
              <div
                class="question_tihao_sanjiao_box box_sizing flex_box flex_diection"
              >
                <div class="question_tihao_sj_shang"></div>
                <div class="question_tihao_sj_xia"></div>
              </div>
            </div> -->
          <!-- </div>
        </div> -->

        <div class="question_list_box">
          <div
            class="question_list box_sizing flex_box flex_align_center flex_justify_between"
            v-for="(item, index) in list"
            :key="index"
          >
            <div
              class="question_list_info box_sizing flex_box flex_align_center"
              @click="jumpDetail(item.id)"
            >
              <div class="question_list_tihao">{{ item.number }}</div>
              <div class="question_list_title">{{ item.name }}</div>
              <div class="question_list_pinlv" v-if="item.high_status == 1">
                高频
              </div>
              <div class="question_list_gengxin" v-if="item.update == 1">
                更新
              </div>
              <div  v-if="item.tags_text != ''" style="display: flex" >
                <div v-for="(names,indexs) in item.tags_text.split(',')" :key="indexs" class="question_list_tags">
                  {{names}}
                </div>
              </div>
              <div
                class="question_list_jiangjie flex_box flex_align_center"
                v-if="item.topic_lecture_id != 0"
              >
                <img
                  class="question_list_jiangjieimg"
                  src="../assets/images/jiangjie.png"
                  alt=""
                />
                名师讲解
              </div>
            </div>
            <div class="box_sizing flex_box flex_align_center justify_end">
              <div class="question_lian_cishu">
                练过{{ item.topic_exercise_count }}次
              </div>
              <div class="question_lian_riqi" v-if="item.createtime_text">
                {{ item.createtime_text }}
              </div>
              <div class="question_lian_shoucang">
                <img
                  class="question_list_jiangjieimg"
                  @click="collowCilck(index)"
                  v-if="!item.topic_collect || item.topic_collect == 1"
                  src="../assets/images/shoucang1.png"
                  alt=""
                />
                <img
                  class="question_list_jiangjieimg"
                  @click="collowCilck(index)"
                  v-if="item.topic_collect == 2"
                  src="../assets/images/shoucang2.png"
                  alt=""
                />
                <img
                  class="question_list_jiangjieimg"
                  @click="collowCilck(index)"
                  v-if="item.topic_collect == 3"
                  src="../assets/images/shoucang3.png"
                  alt=""
                />
                <img
                  class="question_list_jiangjieimg"
                  @click="collowCilck(index)"
                  v-if="item.topic_collect == 4"
                  src="../assets/images/shoucang4.png"
                  alt=""
                />
                <img
                  class="question_list_jiangjieimg"
                  @click="collowCilck(index)"
                  v-if="item.topic_collect == 5"
                  src="../assets/images/shoucang5.png"
                  alt=""
                />
                <div class="question_list_sc_box" v-if="item.show">
                  <div class="question_list_sc_list box_sizing">
                    <div
                      class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                      @click="collectFun(index, item, 1)"
                    >
                      <img
                        class="question_list_jiangjieimg"
                        src="../assets/images/shoucang1.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="question_list_sc_list box_sizing">
                    <div
                      class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                      @click="collectFun(index, item, 2)"
                    >
                      <img
                        class="question_list_jiangjieimg"
                        src="../assets/images/shoucang2.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="question_list_sc_list box_sizing">
                    <div
                      class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                      @click="collectFun(index, item, 3)"
                    >
                      <img
                        class="question_list_jiangjieimg"
                        src="../assets/images/shoucang3.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="question_list_sc_list box_sizing">
                    <div
                      class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                      @click="collectFun(index, item, 4)"
                    >
                      <img
                        class="question_list_jiangjieimg"
                        src="../assets/images/shoucang4.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="question_list_sc_list box_sizing">
                    <div
                      class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                      @click="collectFun(index, item, 5)"
                    >
                      <img
                        class="question_list_jiangjieimg"
                        src="../assets/images/shoucang5.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex;  justify-content: flex-end;">
          <el-pagination
            :page-size="currentPage.rows"
            layout="prev, pager, next"
            :total="currentPage.total"
            :current-page="currentPage.page"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
import "@/css/questionList.css";

var _this;
export default {
  components: {
    headers,
    footers,
    left,
    right,
    scrollpage,
  },
  data() {
    return {
      orderType:"",
      currentPage: {
        page: 1,
        rows: 10,
        total: 0
      },
      is_sort: false,
      sort_num: 1, // 1为正序,-1位倒序
      title: "",
      id: "",
      list: [],
      jiangjieId: 0,
      scStatus: false,
      jiangjieList: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "有讲解",
        },
        {
          id: 2,
          name: "无讲解",
        },
      ],
      tagList: [],
      tag_id: null,
      lianxiId: 1,
      lianxiList: [
        {
          id: 1,
          name: "可练习",
        },
        {
          id: 2,
          name: "不可练习",
        },
      ],
      pinlvId: 0,
      pinlvList: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "高频",
        },
        {
          id: 2,
          name: "新题",
        },
        {
          id: 3,
          name: "旧题",
        },
      ],
      collectId: undefined,
      collectList: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "未收藏",
        },
      ],
      count: 0,
      exercise_count: 0,
      allList: [],
      audio: null,
      text: "",
    };
  },
  created() {
    _this = this;
    _this.getParams();
    _this.getTags();
    // _this.audio.pause()
  },
  methods: {
    toSearch(searchType){
      this.orderType = this.orderType !== searchType ?searchType:"";
      this.getList()
    },
    pageChange(e) {
      this.currentPage.page = e
      console.log('当前额', _this.currentPage)
      _this.getList()
    },
    getTags() {
      _this.$axios
        .post("exercise.topic_tags/arr", {id:_this.id})
        .then((res) => {

          console.log('当前的res', res);
          if (res.code === 1) {
            _this.tagList.push({'id': '', 'name': '全部'});
            _this.tagList = [..._this.tagList, ...res.data]
          }
        })
    },
    //搜索获取焦点
    focusSearch1() {
    //   this.getList()
    },
    //搜索失去焦点
    blurSearch1() {
      this.getList()
    },
    jumpSearch1() {
      this.getList()
    },
    // 跳转题目详情
    jumpDetail(id) {
      localStorage.setItem('lianxiId',_this.lianxiId);
      localStorage.setItem('pinlvId',_this.pinlvId);
      localStorage.setItem('collectId',_this.collectId == undefined ? '' : _this.collectId);
      localStorage.setItem('jiangjieId',_this.jiangjieId);
      localStorage.setItem('tag_id',_this.tag_id != null ? _this.tag_id : '');
      if (!localStorage.getItem("token")) {
        this.$message.error("请登录后再进行答题");
        return
      } else if (_this.id == 20 || _this.id == 21) {
        // 听写句子
        _this.$router.push({
          path: "/questionType14",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 1) {
        // 朗读句子
        _this.$router.push({
          path: "/questionType1",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 6) {
        // 短文写作
        _this.$router.push({
          path: "/questionType6",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 7) {
        // 总结全文
        _this.$router.push({
          path: "/questionType6",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 8) {
        // 阅读单选
        _this.$router.push({
          path: "/questionType4",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 9) {
        // 阅读多选
        _this.$router.push({
          path: "/questionType7",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 17) {
        // 听力单选
        _this.$router.push({
          path: "/questionType8",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 3) {
        // 描述图片
        _this.$router.push({
          path: "/questionType3",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 2) {
        // 描述图片
        _this.$router.push({
          path: "/questionType9",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 4) {
        // 描述图片
        _this.$router.push({
          path: "/questionType9",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 5) {
        // 短回答
        _this.$router.push({
          path: "/questionType9",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 10) {
        // 段落排序
        _this.$router.push({
          path: "/questionType10",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 11) {
        // 阅读填空
        _this.$router.push({
          path: "/questionType11",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 12) {
        // 阅读写作填空
        _this.$router.push({
          path: "/questionType12",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 16) {
        // 摘要选择
        _this.$router.push({
          path: "/questionType8",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 18) {
        // 补全对话
        _this.$router.push({
          path: "/questionType8",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 13) {
        // 录音总结
        _this.$router.push({
          path: "/questionType14",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 15) {
        // 听力填空
        _this.$router.push({
          path: "/questionType15",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 19) {
        // 识别错词
        _this.$router.push({
          path: "/questionType16",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 14) {
        // 听力多选
        _this.$router.push({
          path: "/questionType17",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 22) {
        // 情景描述
        _this.$router.push({
          path: "/questionType9",
          query: {
            id: id,
            name: _this.title,
          },
        });
      } else if (_this.id == 23) {
        // 大作文（CORE）
        _this.$router.push({
          path: "/questionType6",
          query: {
            id: id,
            name: _this.title,
          },
        });
      }
    },

    //选择收藏哪个
    collowCilck(i) {
      let list = _this.list;
      list.forEach((item, index) => {
        if (i == index && !item.show) {
          item.show = true;
        } else {
          item.show = false;
        }
      });

      _this.list = list;
    },

    // 收藏题目
    collectFun(i, obj, type) {
      // console.log(i)
      let list = _this.list;
      let params = {
        topic_id: obj.id,
        type: type,
      };

      if (localStorage.getItem("token")) {
        _this.$axios
          .post("exercise.topic_collect/collect", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              list.forEach((item, index) => {
                if (i == index) {
                  item.topic_collect = type;
                  item.show = false;
                }
              });
              _this.list = list;
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },

    // 获取练习列表
    getList() {
      var list = [];
      var params = {
        text: _this.text,
        topic_type_id: _this.id,
        page: _this.currentPage.page,
        rows: _this.currentPage.rows,
        exercise_status: _this.lianxiId,


        high_status: _this.pinlvId == 1 ? 1 : undefined, //高频开关 0=关1=开
        time_status: _this.pinlvId < 2 ? undefined : _this.pinlvId == 2 ? 1 : 0, //0=旧题1=新题
        topic_collect: _this.collectId, //收藏 之前添加传的
        topic_lecture_id:
          _this.jiangjieId == 0 ? undefined : _this.jiangjieId == 1 ? 1 : 0, //名师讲解id 0=没有讲解
        tags: _this.tag_id

      };

      if (_this.orderType !== ""){
        params.orderType = _this.orderType
      }
      _this.$axios
        .post("exercise.topic/getTopicList", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            res.data.list.data.forEach((item, index) => {
              item.show = false;
              if (item.exercise_status == 1) {
                list.push(item);
              }
            });
            _this.allList = res.data.list.data;
            _this.list = list;
            _this.currentPage.page = res.data.list.current_page;
            _this.currentPage.total = res.data.list.total;
            _this.count = res.data.list.total;
            _this.exercise_count = res.data.exercise_count;
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取传递过来的参数
    getParams() {
      _this.id = _this.$route.query.id;
      _this.title = _this.$route.query.name;
      _this.getList();
    },

    // 修改联系状态
    lianxiChange(e) {
      console.log(e);
      let indexLx = e.target.options.selectedIndex;
      _this.lianxiId = _this.lianxiList[indexLx].id;
      console.log(_this.lianxiId, "修改联系状态");
      _this.screenList();
      _this.list = [];
      _this.getList()
    },

    // 修改频率状态
    pinlvChange(e) {
      console.log(e);
      let indexPl = e.target.options.selectedIndex;
      _this.pinlvId = _this.pinlvList[indexPl].id;
      console.log(_this.pinlvId);
      _this.screenList();
      _this.list = [];
      _this.getList();
    },
    // 收藏筛选
    screenCollect(index) {
      _this.collectId = index;
      _this.scStatus = false;
      // _this.screenList();
      _this.list = []
      _this.getList()
    },
    // 手动筛选
    // /**
    //  * @param {number} type [1:可练,2:频率,3:收藏,4,讲解]
    //  * @param {number} value [对应type的转态值]
    //  */
    screenList() {
      let type_obj = {
        exercise_status: _this.lianxiId, //练习状态 1=可练习2=不可练习
        high_status: _this.pinlvId == 1 ? 1 : undefined, //高频开关 0=关1=开
        time_status: _this.pinlvId < 2 ? undefined : _this.pinlvId == 2 ? 1 : 0, //0=旧题1=新题
        topic_collect: _this.collectId, //收藏 之前添加传的
        topic_lecture_id:
          _this.jiangjieId == 0 ? undefined : _this.jiangjieId == 1 ? 1 : 0, //名师讲解id 0=没有讲解
      };
      let all_list = _this.allList;
      let list = [];
      for (const index in all_list) {
        let istrue = true;
        for (const key in type_obj) {
          if (key == "topic_collect" && all_list[index][key] == null)
            all_list[index][key] = 1;
          if (key == "topic_lecture_id" && all_list[index][key] > 0)
            all_list[index][key] = 1;
          if (
            type_obj[key] != undefined &&
            all_list[index][key] != type_obj[key]
          )
            istrue = false;
        }
        if (istrue) list.push(all_list[index]);
      }

      _this.list = list;
    },

    // 切换讲解
    jiangjieChange(e) {
      // _this.screenList();
      _this.list = []
      _this.getList()
    },
    tagChange() {
      // _this.screenList();
      _this.list = []
      _this.getList()
    },
    sortList() {
      const compare = (obj1, obj2) => {
        let val1 = obj1.number;
        let val2 = obj2.number;
        if (val1 < val2) {
          return -1 * _this.sort_num;
        } else if (val1 > val2) {
          return 1 * _this.sort_num;
        } else {
          return 0;
        }
      };
      console.log(_this.sort_num)
      if (_this.sort_num === 1) {
        _this.sort_num = -1;
      } else {
        _this.sort_num = 1;
      }
      _this.list = _this.list.sort(compare);
    },
  },
};
</script>


<style scoped>
.clear_img {
  position: absolute;
  left: 60px;
  top: 5px;
  width: 16px;
  height: 16px;
}

.pointer {
  cursor: pointer;
  user-select: none;
}

.color_black {
  color: black !important;
}
</style>
