<template>
    <div class="container">
        <headers></headers>
        <right></right>
        <scrollpage></scrollpage>

        <div  class="w box_sizing width_box my_wrap">
            <div class="left_position">
                <left></left>
            </div>

            <div class="banner_detail box_sizing" v-html="content">
                {{content}}
            </div>


        </div>


        <footers></footers>
        
    </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
var _this;
export default {
    components: {
        headers,
        footers,
        left,
        right,
        scrollpage,
    },
    data() {
        return {
            content: '',
            id: ''
        }
    },
    created() {
        _this = this;
        _this.getParams()
    },
    mounted() {},
    methods: {

        // 获取传递过来的参数
        getParams() {
            _this.id = _this.$route.query.id;
            _this.getDetail();
        },

        // 获取攻略详情
        getDetail() {
            var d = {
                id: _this.id,
            };
            _this.$axios
                .post("faq/getInfo", d)
                .then((res) => {
                    console.log(res);
                    if (res.code == 1) {
                        _this.content = res.data;
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    }
    
}
</script>


<style>
    .banner_detail {
        width: 100%;
        padding: 32px;
        border-radius: 20px;
        background: rgba(255, 255, 255, 1);
        text-align: center;
    }
</style>
