<template>
	<div class="container">
		<div class="top   ">
			<div class="header_wrap box_sizing flex_box flex_align_center">
				<div class="top_logo">
					<img src="../assets/images/logo_min.png" alt="" />
				</div>
				<div class="box_sizing flex_box flex_align_center" style="margin-right: 10px;">
					<div class="question_flex_toplist flex_1">
						<div
						class="question_toplist_select top_list2 box_sizing flex_box flex_align_center flex_justify_between"
						>
						<el-select v-model="typeId" placeholder="请选择" style="width: 120px !important;">
							<el-option-group
							v-for="group in typeList"
							:key="group.label"
							:label="group.label">
							<el-option
								v-for="item in group.options"
								:key="item.value"
								:label="item.label"
								:value="item.value">
							</el-option>
							</el-option-group>
						</el-select>
						<!-- <div class="question_toplist_sanjiao"></div> -->
						</div>
					</div>
				</div>
				<div class="search_top box_sizing flex_box flex_align_center">
					<div class="search_input flex_1">
						<input type="text" v-model="text" placeholder="输入关键词搜索" @blur="blurSearch"
							@focus="focusSearch" />
					</div>
					<div class="search_icon box_sizing" @click="jumpSearch">
						<img src="../assets/images/search_icon.png" alt="" />
					</div>
				</div>
				<el-card class="search_card" v-if="isSearchCard">
					<div class="card_top">
						<div>搜索历史</div>
						<div class="card_clear" @mousedown="clearSearch">清空历史记录</div>
					</div>

					<div class="card_log">
						<div v-for="(item, index) in searchLog" :key="index">
							<div class="card_text" @mousedown="text = item">
								{{ item }}
							</div>
						</div>
					</div>
				</el-card>

				<div class="user_box flex_box flex_align_center" v-if="isLogin">
					<div class="user_img">
						<div class="user_icon">
							<img :src="avatar" alt="" />
						</div>
						<!-- <div class="user_vip">
                        <img src="../assets/images/vip_icon.png" alt="">
                    </div> -->
					</div>
					<div class="flex_box flex_diection">
						<div class="user_name">{{ nickname }}</div>
						<!-- <div class="user_vip_time">vip到期：{{time}}</div> -->
					</div>
				</div>
				<div class="flex_box flex_align_center" v-else @click="loginStatus = true">
					<div class="user_img">
						<div class="user_icon">
							<img :src="userImage" alt="" />
						</div>
					</div>
					<div class="user_login_txt">立即登录</div>
					<!-- <div class="user_login_txt">立即</div> -->
				</div>
			</div>
		</div>

		<!-- 微信登录 -->
		<div class="login_wrap now" v-if="loginWx">
			<div class="login_wx_top_box flex_box flex_justify_center box_sizing flex_align_center flex_diection">
				<div class="login_close" @click="loginWx = false">
					<img src="../assets/images/login_close.png" alt="" />
				</div>
				<div class="login_wx_title">扫码登录</div>
				<div class="login_wx_img">
					<img src="../assets/images/wx_img.png" alt="" />
				</div>
				<div class="login_wx_txt">微信扫一扫即可登录</div>
			</div>
		</div>

		<!-- 手机号登录 -->
		<div class="login_wrap" v-if="loginStatus">
			<div class="login_box box_sizing flex_box flex_diection">
				<div class="login_close" @click="loginStatus = false">
					<img src="../assets/images/login_close.png" alt="" />
				</div>
				<div class="login_top">手机验证码登录</div>
				<div class="login_list box_sizing flex_box">
					<div class="login_icon">
						<img src="../assets/images/login1.png" alt="" />
					</div>
					<div class="login_input flex_1">
						<input type="number" maxlength="11" placeholder="请输入手机号" v-model="mobile" />
					</div>
				</div>
				<div class="login_list box_sizing flex_box">
					<div class="login_icon">
						<img src="../assets/images/login2.png" alt="" />
					</div>
					<div class="login_input flex_1">
						<input type="number" placeholder="请输入验证码" v-model="code" />
					</div>
					<div class="login_get_code now" v-if="status">重发({{ sec }})</div>
					<div class="login_get_code hand_change" v-else @click="getCode">获取验证码</div>
				</div>
				<div class="login_btn hand_change" @click="login">登录</div>
				<div class="login_wx_box flex_box flex_align_center flex_diection">
					<div class="login_wx hand_change" @click="getLoginWx">
						<img src="../assets/images/login_wx.png" alt="" />
					</div>
					<div class="login_wx_txt hand_change">微信二维码登录</div>
				</div>
			</div>
		</div>

		<!-- 绑定微信 -->
		<div class="login_wrap" v-if="wxAuthStatus">
			<div class="login_box box_sizing flex_box flex_diection">
				<div class="login_close" @click="loginStatus = false">
					<img src="../assets/images/login_close.png" alt="" />
				</div>
				<div class="login_top">微信绑定手机号</div>
				<div class="login_list box_sizing flex_box">
					<div class="login_icon">
						<img src="../assets/images/login1.png" alt="" />
					</div>
					<div class="login_input flex_1">
						<input type="number" maxlength="11" placeholder="请输入手机号" v-model="mobile" />
					</div>
				</div>
				<div class="login_list box_sizing flex_box">
					<div class="login_icon">
						<img src="../assets/images/login2.png" alt="" />
					</div>
					<div class="login_input flex_1">
						<input type="number" placeholder="请输入验证码" v-model="code" />
					</div>
					<div class="login_get_code now" v-if="status">重发({{ sec }})</div>
					<div class="login_get_code" v-else @click="getCode">获取验证码</div>
				</div>
				<div class="login_btn" @click="login">登录</div>
				<div class="login_wx_box flex_box flex_align_center flex_diection">
					<div class="login_wx" @click="getLoginWx">
						<img src="../assets/images/login_wx.png" alt="" />
					</div>
					<div class="login_wx_txt">微信二维码登录</div>
				</div>
			</div>
		</div>
		<div style="width: 100%;height: 120px"></div>
	</div>
</template>
<script src="https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
	import {
		mapState,
		mapMutations,
		mapActions,
		mapGetters
	} from "vuex";
	var _this;
	export default {
		name: "headers",
		data() {
			return {
				wxAuthStatus: false,
				status: false,
				loginStatus: true,
				loginWx: false,
				isLogin: false,
				isSearchCard: false,
				text: "",
				time: "2020.09.22",
				sec: 0,
				typeId: "",
				typeList: [],
				mobile: "",
				code: "",
				thirdid: "",
				userImage: "https://yijishen.qiniu.bronet.cn/uploads/20201116/FpUmbPlH8Obr07XUqla73aVW1sm9.png",
				userImg: require("../assets/images/user_img.png"),
				reqParams: {
					appid: "wx528aba34317dbe2f",
					scope: "snsapi_login",
					redirect_uri: encodeURI(window.location.href),
					// redirect_uri:'https://yijishen.tvue.brofirst.cn',
					
					state: "123",
					login_type: "jssdk",
					self_redirect: false,
					myWindow: "",
				},
				searchLog: []
			};
		},
		computed: {
			...mapState(["nickname", "avatar"]),
		},
		activated() {
			_this = this;
			console.log('created1');
			if (localStorage.getItem("token")) {
				_this.isLogin = true;
				_this.getUser();
                _this.loginStatus = false
			} else {
				var code = _this.$route.query.code;
				console.log('999999999999999999999999999999999999', code)
				if (code) {
					_this.$axios
						.post("wechat/loginCallback", {
							code: code
						})
						.then((res) => {
							if (res.data.thirdid) {
								_this.thirdid = res.data.thirdid;
								_this.wxAuthStatus = true;
								// _this.$router.replace({ path: '/WxAuth?thirdid=' + res.data.thirdid, replace: true });
							} else {
								console.log(res.data.userinfo, "登录");
								localStorage.setItem("token", res.data.userinfo.token);
								_this.getUser();
								// _this.$router.push("/");
							}
						})
						.catch((err) => {
							_this.$message.error(err.msg);
						});
				}
			}

			_this.text = _this.$route.query.keyword;
		},
		created() {
			_this = this;
			console.log('created');
			_this.typeId = _this.$route.query.typeId ? parseInt(_this.$route.query.typeId) : "";
			_this.typelist();
			if (localStorage.getItem("token")) {
				_this.isLogin = true;
				_this.getUser();
                _this.loginStatus = false
			} else {
				var code = _this.$route.query.code;
				console.log('999999999999999999999999999999999999', code)
				if (code) {
					_this.$axios
						.post("wechat/loginCallback", {
							code: code
						})
						.then((res) => {
							if (res.data.thirdid) {
								_this.thirdid = res.data.thirdid;
								_this.wxAuthStatus = true;
								// _this.$router.replace({ path: '/WxAuth?thirdid=' + res.data.thirdid, replace: true });
							} else {
								console.log(res.data.userinfo, "登录");
								localStorage.setItem("token", res.data.userinfo.token);
								_this.getUser();
								// _this.$router.push("/");
							}
						})
						.catch((err) => {
							_this.$message.error(err.msg);
						});
				}
			}
			_this.text = _this.$route.query.keyword;

		},
		methods: {
			...mapActions(["userinfo"]),

			// 获取微信登录二维码
			getLoginWx() {
				(window.location.href =
					"https://open.weixin.qq.com/connect/qrconnect?appid=" +
					_this.reqParams.appid +
					"&scope=" +
					_this.reqParams.scope +
					"&redirect_uri=" +
					_this.reqParams.redirect_uri +
					"&state=" +
					_this.reqParams.state +
					"#wechat_redirect"),
				"微信登录",
				"width=540,height=540";
			},
			// 获取题型列表
			typelist() {
				var params = {
				};
				_this.$axios
					.post("exercise.topic_type/getTypeList2", params)
					.then((res) => {
					console.log(res);
					if (res.code == 1) {
						res.data.forEach((item, index) => {
						});
						_this.typeList = res.data;
					} else {
						_this.$message.error(res.msg);
					}
					})
					.catch((err) => {
					console.log(err);
				});
			},

			// 获取用户信息
			getUser() {
				_this.$axios
					.get("user/userInfo", {})
					.then((res) => {
						if (res.code == 1) {
							let userInfo = {
								nickname: res.data.nickname,
								avatar: res.data.avatar,
							};
							_this.userinfo(userInfo);
							_this.$forceUpdate();
						} else {
							_this.$message.error(res.msg);
						}
					})
					.catch((err) => {
						_this.$message.error(err.msg);
					});
			},

			//搜索获取焦点
			focusSearch() {
				this.getSearchLog()
				this.isSearchCard = true;
			},

			//搜索失去焦点
			blurSearch() {
				this.isSearchCard = false;
			},

			getSearchLog() {
				_this.$axios
					.post("keyword_log/getKeyword", {})
					.then((res) => {
						if (res.code == 1) {
							_this.searchLog = res.data;
						} else {
							_this.$message.error(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},

			clearSearch() {
				_this.$axios
					.post("keyword_log/delKeyword", {})
					.then((res) => {
						if (res.code == 1) {
							_this.searchLog = [];
						} else {
							_this.$message.error(res.msg);
						}
						})
					.catch((err) => {
						console.log(err);
					});
			},

			// 跳转搜索结果页
			jumpSearch() {
				if (_this.$route.path == '/questionSearchList') {
					_this.$router.push({
						query: {..._this.$route.query, keyword:_this.text, typeId: _this.typeId}
					})

					_this.$parent.getParams()
				}else{
					_this.$router.push({
						path: "/questionSearchList",
						query: {
							keyword: _this.text,
							typeId: _this.typeId
						},
						replace: true
					});
				}

			},

			// 登录
			login() {
				var regular = /^1[3456789]\d{9}$/,
					url =
					"user/mobilelogin?mobile=" +
					_this.mobile +
					"&code=" +
					_this.code +
					"&thirdid=" +
					_this.thirdid;
				if (_this.mobile == "") {
					_this.$message.error("请输入手机号");
				} else if (!regular.test(_this.mobile)) {
					_this.$message.error("请输入正确的手机号");
				} else {
					_this.$axios
						.get(url, {})
						.then((res) => {
							console.log(res);
							if (res.code == 1) {
                _this.loginStatus = false
								localStorage.setItem("token", res.data.userinfo.token);
								_this.loginStatus = false;
								_this.wxAuthStatus = false;
								setTimeout(() => {
									_this.getUser();
									_this.isLogin = true;
                  location.reload()
								}, 100);
							} else {
								_this.$message.error(res.msg);
							}
						})
						.catch((err) => {
							console.log(err);
							_this.$message.error(err.msg);
						});
				}
			},

			// 获取验证码
			getCode() {
				var regular = /^1[3456789]\d{9}$/;
				if (_this.mobile == "") {
					_this.$message.error("请输入手机号");
				} else if (!regular.test(_this.mobile)) {
					_this.$message.error("请输入正确的手机号");
				} else {
					_this.$axios
						.get("sms/send?mobile=" + _this.mobile, {})
						.then((res) => {
							console.log(res);
							if (res.code == 1) {
								_this.$message.success("发送成功");
								var num = 60;
								var timer = setInterval(function() {
									num--;
									if (num <= 0) {
										clearInterval(timer);
										_this.status = false;
									} else {
										_this.status = true;
										_this.sec = num;
									}
								}, 1000);
							} else {
								_this.$message.error(res.msg);
							}
						})
						.catch((err) => {
							console.log(err);
							_this.$message.error(err.msg);
						});
				}
			},
		},
	};
</script>


<style>
.top {
	width: 100%;
	/* height: 100px; */
	background: rgba(255, 255, 255, 1);
	/* padding: 0 360px; */
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
}

.header_wrap {
	width: 1200px;
	height: 100px;
	margin: 0 auto;
}

.top_logo {
	margin-right: 63px;
}

.top_logo img {
	width: 170px;
	height: 65px;
}

.search_top {
	width: 600px;
	height: 34px;
	border-radius: 18px;
	padding: 6px 28px;
	background: rgba(242, 242, 242, 1);
	margin: 0 70px 0 0;
}

.search_input {
	min-width: 200px;
	height: 100%;
}

.search_card {
	position: absolute;
	top: 68px;
	width: 600px;
	margin-left: 348px;
	border-radius: 8px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.search_card .card_top {
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	font-weight: 400;
}

.search_card .card_top .card_clear {
	text-decoration: underline;
	color: #8c9198;
	letter-spacing: 0.6px;
	cursor: pointer;

}

.search_card .card_log {
	display: flex;
	flex-wrap: wrap;
}

.search_card .card_log .card_text {
	margin: 13px 10px 10px 10px;
	padding: 5px 12px;
	background: #f9f9f9;
	border-radius: 4px;
}


.search_icon {
	width: 14px;
	height: 14px;
}

.user_img {
	width: 34px;
	height: 34px;
	margin-right: 10px;
	position: relative;
}

.user_icon {
	width: 34px;
	height: 34px;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 2;
	border-radius: 50%;
	overflow: hidden;
}

.user_vip {
	width: 16px;
	height: 16px;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 3;
}

.user_name {
	font-size: 14px;
	color: rgba(61, 68, 77, 1);
	font-weight: bold;
}

.user_vip_time {
	font-size: 12px;
	color: rgba(61, 68, 77, 1);
}

.user_login_txt {
	font-size: 14px;
	color: rgba(61, 68, 77, 1);
	font-weight: bold;
}
</style>
