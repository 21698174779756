<template>
    <div class="container">
        <div class="left_wrap box_sizing">
            <div class="user">
                <img v-if="avatar" :src="avatar" alt="">
                <img v-else :src="userImage" alt="">
            </div>
            <div class="left_list box_sizing flex_box flex_align_center justify-start" v-for="(item, index) in list1" :key='"list" + index' @click="jumpPage(item.url)">
                <div class="list_icon">
                    <img :src="item.image" alt="">
                </div>
                <div class="list_title">{{item.title}}</div>
            </div>
             <div @click="logout" class="left_list box_sizing flex_box flex_align_center justify-start" v-if="get_token != null">
                <div class="list_icon">
                    <img src="../assets/images/guanbi_icon@2x.png" alt="">
                </div>
                <div class="list_title">退出登录</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex"
var _this;
export default {
    name: 'left',
    data() {
        return {
            userImage: 'https://yijishen.qiniu.bronet.cn/uploads/20201116/FpUmbPlH8Obr07XUqla73aVW1sm9.png',
            list1: [
                {
                    image: require("../assets/images/left0.png"),
                    title: '首页',
                    id: 1,
                    url: '/'
                },
                {
                    image: require("../assets/images/left9.png"),
                    title: '个人中心',
                    id: 10,
                    url: '/my'
                },
                {
                    image: require("../assets/images/left3.png"),
                    title: '练习',
                    id: 4,
                    url: '/practice'
                },
                //  {
                //      image: require("../assets/images/left4.png"),
                //      title: '模考',
                //      id: 5,
                //      url: '/mokao'
                //  },
                 {
                    image: require("../assets/images/left6.png"),
                    title: '单词本',
                    id: 7,
                    url: '/words'
                },
                // {
                //     image: require("../assets/images/left10.png"),
                //     title: 'PTE攻略',
                //     id: 11,
                //     url: '/strategyList'
                // }

            ],
            list: [
                {
                    image: require("../assets/images/left0.png"),
                    title: '首页',
                    id: 1,
                    url: '/'
                },
                {
                    image: require("../assets/images/left1.png"),
                    title: '学习中心',
                    id: 2,
                    url: ''
                },
                {
                    image: require("../assets/images/left2.png"),
                    title: '课程',
                    id: 3,
                    url: ''
                },
                {
                    image: require("../assets/images/left3.png"),
                    title: '练习',
                    id: 4,
                    url: ''
                },
                {
                    image: require("../assets/images/left4.png"),
                    title: '模考',
                    id: 5,
                    url: ''
                },
                {
                    image: require("../assets/images/left5.png"),
                    title: '成绩分析',
                    id: 6,
                    url: ''
                },
                {
                    image: require("../assets/images/left6.png"),
                    title: '单词本',
                    id: 7,
                    url: '/words'
                },
                {
                    image: require("../assets/images/left7.png"),
                    title: '口语分提前测',
                    id: 8,
                    url: ''
                },
                {
                    image: require("../assets/images/left8.png"),
                    title: '会员充值',
                    id: 9,
                    url: ''
                },
                {
                    image: require("../assets/images/left9.png"),
                    title: '个人中心',
                    id: 10,
                    url: '/my'
                },
                {
                    image: require("../assets/images/left10.png"),
                    title: 'PTE攻略',
                    id: 11,
                    url: '/strategyList'
                }
            ]
        }
    },
    computed: {
        ...mapState(['nickname', 'avatar']),
      get_token(){
          console.log('当前的token', localStorage.getItem('token'))
        return localStorage.getItem('token')
      }
    },
    created() {
        _this = this;
        if(localStorage.getItem("token")) {
            _this.isLogin = true;
        }
    },
    methods: {
        ...mapActions(['logout']),
        // 跳转页面
        jumpPage(url) {
            _this.$router.push({
                path: url
            })
        },
    }
}
</script>

<style>
    .left_wrap {
        width: 180px;
        padding: 36px 0 14px;
        background: rgba(255, 255, 255, 1);
        border-radius: 10px;
    }

    .user {
        width: 74px;
        height: 74px;
        position: absolute;
        top: -48px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        overflow: hidden;
        background: rgba(255, 255, 255, 1);
    }

    .left_list {
        width: 100%;
        height: 44px;
        padding: 0 20px;
    }

    .list_icon {
        width: 24px;
        height: 24px;
        margin-right: 14px;
    }

    .list_title {
        font-size: 14px;
        color: rgba(140, 145, 152, 1);
    }


</style>
