import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import plugin from '@/components'
import axios from "@/api";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueECharts from 'vue-echarts';
// import './css/swiper.min.css' // 同时必须引入css样式文件
import 'swiper/swiper-bundle.css'
Vue.prototype.$axios = axios;
import uploadFile from "@/api";
Vue.prototype.$uploadFile = uploadFile;

Vue.component("v-chart", VueECharts);


if (localStorage.getItem("token")) {
  axios.defaults.headers.common["token"] = localStorage.getItem("token");
}
let jsonData = {
  // uploadUrl:"http://bronet.cn:7023/"
  uploadUrl:"https://yijishen.brofirst.cn/",
  qiniuUrl:"https://yijishen.qiniu.bronet.cn",
  baseUrl:"https://yijishen.brofirst.cn/admin.php/topic_exercise/check_list?ref=addtabs"
}
Vue.prototype.$global = jsonData;

import VueClipboard from "vue-clipboard2";

import '@/css/common.css'

Vue.use(ElementUI)
Vue.use(plugin)
Vue.use(VueAwesomeSwiper)

Vue.use(VueClipboard)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
