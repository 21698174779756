<template>
  <div class="container">
    <headers></headers>
    <!-- <right></right> -->
    <scrollpage></scrollpage>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>
      <div class="my_box">
        <div class="my_box_padding">
          <div class="my_box_user box_sizing flex_box flex_justify_between">
            <div class="box_sizing flex_box">
              <div class="my_box_left_user">
                <img
                  class="my_user_left_img"
                  :src="avatar ? avatar : userImg"
                  alt=""
                />
                <!-- <img class="my_user_left_vip" src="../assets/images/vip.png" alt=""> -->
              </div>
              <div class="my_user_info_right">
                <div
                  class="my_userinfo_top box_sizing flex_box flex_align_center"
                >
                  <div class="my_userinfo_name">{{ nickname }}</div>
                  <div class="my_userinfo_icon">
                    <img src="../assets/images/edit_info.png" alt="" />
                  </div>
                </div>
                <!-- <div class="my_userinfo_bottom box_sizing flex_box flex_align_center">
                                    <div class="my_userinfo_bottom_txt">vip到期时间：2020.09.09</div>
                                    <div class="my_userinfo_bottom_btn">立即续费</div>
                                </div> -->
              </div>
            </div>
            <div class="my_info_box box_sizing flex_box flex_align_center">
<!--                  <div-->
<!--                    class="my_info_list box_sizing flex_box flex_justify_center flex_align_center flex_diection"-->
<!--                    @click="jumpMyPractic"-->
<!--                  >-->
<!--                <div class="my_info_list_icon">-->
<!--                  <img src="../assets/images/left3.png" alt="" />-->
<!--                </div>-->
<!--                <div class="my_info_list_txt">我的批改申请</div>-->
<!--              </div>-->
              <div
                class="my_info_list box_sizing flex_box flex_justify_center flex_align_center flex_diection"
                @click="jumpMyCouse"
              >
                <div class="my_info_list_icon">
                  <img src="../assets/images/kecheng.png" alt="" />
                </div>
                <div class="my_info_list_txt">我的课程</div>
              </div>
              <!-- <div class="my_info_list box_sizing flex_box flex_justify_center flex_align_center flex_diection">
                  <div class="my_info_list_icon">
                      <img src="../assets/images/shoucang.png" alt="">
                  </div>
                  <div class="my_info_list_txt">我的收藏</div>
              </div> -->
              <div class="my_info_list box_sizing flex_box flex_justify_center flex_align_center flex_diection"
                @click="jumpMyExam">
                  <div class="my_info_list_icon">
                      <img src="../assets/images/jilu.png" alt="">
                  </div>
                  <div class="my_info_list_txt">模考记录</div>
              </div> 
            </div>
          </div>

          <div class="my_time_box box_sizing">
            <div class="my_time_top box_sizing flex_box flex_align_center">
              <div
                class="my_time_top_list box_sizing flex_box flex_justify_between flex_align_center flex_diection"
                style="cursor: auto"
              >
                <div
                  class="my_time_top_txt"
                  :class="[timeStatus == 1 ? 'now' : '']"
                >
                  总练习时长： {{ study_info.totalTime }}
                </div>
                <div class="my_time_top_line" v-if="timeStatus == 1"></div>
              </div>
              <div
                class="my_time_top_list box_sizing flex_box flex_justify_between flex_align_center flex_diection"
                style="cursor: auto"
              >
                <div
                  class="my_time_top_txt"
                  :class="[timeStatus == 2 ? 'now' : '']"
                >
                  今天练习时长： {{ study_info.todayTime }}
                </div>
                <div class="my_time_top_line" v-if="timeStatus == 2"></div>
              </div>
            </div>
            <div class="my_time_bottom box_sizing flex_box flex_align_center">
              <div
                class="my_time_bottom_list box_sizing flex_box flex_align_center"
              >
                <div class="my_time_bottom_img">
                  <img src="../assets/images/lianxi01.png" alt="" />
                </div>
                <div class="my_time_bottom_right flex_1">
                  <div class="my_time_bottom_title">Speaking</div>
                  <div class="my_time_bottom_time">
                    {{ study_info.timeData.speaking }}
                  </div>
                </div>
              </div>
              <div
                class="my_time_bottom_list box_sizing flex_box flex_align_center"
              >
                <div class="my_time_bottom_img">
                  <img src="../assets/images/lianxi02.png" alt="" />
                </div>
                <div class="my_time_bottom_right flex_1">
                  <div class="my_time_bottom_title">Listening</div>
                  <div class="my_time_bottom_time">
                    {{ study_info.timeData.listening }}
                  </div>
                </div>
              </div>
              <div
                class="my_time_bottom_list box_sizing flex_box flex_align_center"
              >
                <div class="my_time_bottom_img">
                  <img src="../assets/images/lianxi03.png" alt="" />
                </div>
                <div class="my_time_bottom_right flex_1">
                  <div class="my_time_bottom_title">Writing</div>
                  <div class="my_time_bottom_time">
                    {{ study_info.timeData.writing }}
                  </div>
                </div>
              </div>
              <div
                class="my_time_bottom_list box_sizing flex_box flex_align_center"
              >
                <div class="my_time_bottom_img">
                  <img src="../assets/images/lianxi04.png" alt="" />
                </div>
                <div class="my_time_bottom_right flex_1">
                  <div class="my_time_bottom_title">Reading</div>
                  <div class="my_time_bottom_time">
                    {{ study_info.timeData.reading }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="my_time_box box_sizing">
            <div class="my_time_top box_sizing flex_box flex_align_center">
              <div
                class="my_time_top_list box_sizing flex_box flex_justify_between flex_align_center flex_diection"
                style="cursor: auto"
              >
                <div
                  class="my_time_top_txt"
                  :class="[timeStatus == 1 ? 'now' : '']"
                >
                  总练习题量：{{ study_info.totalNumber }}题
                </div>
                <div class="my_time_top_line" v-if="timeStatus == 1"></div>
              </div>
              <div
                class="my_time_top_list box_sizing flex_box flex_justify_between flex_align_center flex_diection"
                style="cursor: auto"
              >
                <div
                  class="my_time_top_txt"
                  :class="[timeStatus == 2 ? 'now' : '']"
                >
                  今天练习题量：{{ study_info.todayNumber }}题
                </div>
                <div class="my_time_top_line" v-if="timeStatus == 2"></div>
              </div>
            </div>
            <div class="my_time_bottom box_sizing flex_box flex_align_center">
              <div
                class="my_time_bottom_list box_sizing flex_box flex_align_center"
              >
                <div class="my_time_bottom_img">
                  <img src="../assets/images/lianxi01.png" alt="" />
                </div>
                <div class="my_time_bottom_right flex_1">
                  <div class="my_time_bottom_title">Speaking</div>
                  <div class="my_time_bottom_time">
                    {{ study_info.numberData.speaking }}题
                  </div>
                </div>
              </div>
              <div
                class="my_time_bottom_list box_sizing flex_box flex_align_center"
              >
                <div class="my_time_bottom_img">
                  <img src="../assets/images/lianxi02.png" alt="" />
                </div>
                <div class="my_time_bottom_right flex_1">
                  <div class="my_time_bottom_title">Listening</div>
                  <div class="my_time_bottom_time">
                    {{ study_info.numberData.listening }}题
                  </div>
                </div>
              </div>
              <div
                class="my_time_bottom_list box_sizing flex_box flex_align_center"
              >
                <div class="my_time_bottom_img">
                  <img src="../assets/images/lianxi03.png" alt="" />
                </div>
                <div class="my_time_bottom_right flex_1">
                  <div class="my_time_bottom_title">Writing</div>
                  <div class="my_time_bottom_time">
                    {{ study_info.numberData.writing }}题
                  </div>
                </div>
              </div>
              <div
                class="my_time_bottom_list box_sizing flex_box flex_align_center"
              >
                <div class="my_time_bottom_img">
                  <img src="../assets/images/lianxi04.png" alt="" />
                </div>
                <div class="my_time_bottom_right flex_1">
                  <div class="my_time_bottom_title">Reading</div>
                  <div class="my_time_bottom_time">
                    {{ study_info.numberData.reading }}题
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="my_center_banner_box">
            <div class="my_center_banner_top flex_box flex_align_center">
              <div class="my_center_banner_top_title">我的成绩单</div>
              <div class="my_center_banner_top_txt">AI智能全面分析</div>

              <el-upload
                ref="TSupload"
                class="upload-demo"
                action="https://yijishen.brofirst.cn/api/common/upload"
                :on-change="uploadTranscript"
                :on-remove="clearFiles"
                accept=".jpeg,.png,.jpg,.bmp,.gif"
                :headers="{ token: token }"
                :limit="1"
                :multiple="false"
              >
                <div class="my_center_banner_top_btn">上传成绩单</div>
              </el-upload>
              <!-- <div class="my_center_banner_top_btn">上传成绩单</div> -->
            </div>

            <div class="center_banner_chengji box_sizing">
              <div class="loops box_sizing">
                <swiper :options="swiperOption">
                  <swiper-slide
                    class="swiper_item"
                    v-for="(item, index) in groomList"
                    :key="index"
                  >
                    <div class="list_loops">
                      <div class="img__">
                        <img src="../assets/images/lianxi04.png" alt />
                      </div>
                      <div class="loops_fenxi_btn">智能分析</div>
                    </div>
                    <div class="loops_time">2016.10.20</div>
                  </swiper-slide>
                </swiper>
                <div class="prev btn_1 btn_prev">
                  <img
                    src="../assets/images/zuo_icon.png"
                    class="left-btn sm-btn"
                  />
                </div>
                <div class="next btn_1 btn_next">
                  <img
                    src="../assets/images/you_icon.png"
                    class="right-btn sm-btn"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <footers></footers> -->
  </div>
</template>


<script>
import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
import headers from "@/components/headers";
// import footers from "@/components/footders";
import left from "@/components/left";
// import right from "@/components/right";
import scrollpage from "@/components/scrollpage";

var _this;
export default {
  components: {
    headers,
    // footers,
    left,
    // right,
    scrollpage,
  },
  data() {
    return {
      userImg: require("../assets/images/touxiang.png"),
      timeStatus: 1,
      groomList: [1, 1, 1, 1, 1, 1, 1, 1, 1],
      up: false,
      swiperOption: {
        loop: false,
        slidesPerView: 4,
        spaceBetween: 34,
        freeMode: true, // 不止滑动一格
        navigation: {
          nextEl: ".next",
          prevEl: ".prev",
        },
        swiperSlides: [],
      },
      token: localStorage.getItem("token"),

      study_info: {
        totalTime: 0, //总练习时长
        todayTime: 0, //今日练习时长
        timeData: {
          //时长数据
          speaking: 0,
          listening: 0,
          writing: 0,
          reading: 0,
        },
        totalNumber: 0, //总练习条数
        todayNumber: 0, //今日练习条数
        numberData: {
          //条数数据
          speaking: 0,
          listening: 0,
          writing: 0,
          reading: 0,
        },
        bookNumber: 0, //学习新词
        courseNumber: 0, //购买课程
      },
    };
  },
  computed: {
    ...mapState(["nickname", "avatar"]),
  },

  created() {
    _this = this;
    if (localStorage.getItem("token")) {
      _this.isLogin = true;
      // _this.getUser();
      _this.getStudyTime();
    }
  },
  mounted() {
  },
  methods: {
    // 上传成绩单
    uploadTranscript(file) {
      console.log("file", file);
      if (file?.response?.code == 1) {
        _this.$axios
          .post("user_exam_score/orc", {
            file: file.response.data.url,
          })
          .then((res) => {
            console.log(res);
            this.$refs["TSupload"].clearFiles();
          })
          .catch((err) => {
            console.log(err);
            this.$refs["TSupload"].clearFiles();
          });
      } else {
        this.$message.error(file?.response?.msg || '上传失败');
      }
    },
    // 清空上传文件
    clearFiles() {
      this.$refs["TSupload"].clearFiles();
    },
    // 切换练习时长的type
    timeStatusChange(type) {
      _this.timeStatus = type;
    },

    // 跳转我的课程
    jumpMyCouse() {
      _this.$router.push({
        path: "/myCouse",
      });
    },
    jumpMyPractic() {
      _this.$router.push({
        path: "/myExercise",
      });
    },
    jumpMyExam() {
      _this.$router.push({
        path: "/myExam",
      });
    },


    // 获取用户信息
    getUser() {
      _this.$axios
        .get("user/userInfo", {})
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.name = res.data.nickname;
            _this.avatar = res.data.avatar;
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取学习时长
    getStudyTime() {
      _this.$axios.post("user/getExerciseLog").then((res) => {
        if (res.code == 1) {
          _this.study_info = res.data;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },

    // 重置时长
    resetTime(minutes) {
      console.log(minutes, "minutes");
      let str = "";
      let H = Math.floor(minutes / 60);
      let M = Math.floor(minutes % 60);
      if (minutes <= 60) {
        str = `${minutes}分钟`;
      } else {
        str = `${H}小时${M}分钟`;
      }
      return str;
    },
  },
};
</script>

<style>
</style>


























