<template>
  <div class="container">
    <headers></headers>
    <!-- <right></right> -->
    <scrollpage></scrollpage>

    <div
      class="w box_sizing width_box"
      style="margin-bottom: 56px; width: 1400px; margin-left: 150px"
    >
      <div class="left_position">
        <left></left>
      </div>
      <div class="index_wrap box_sizing">
        <div class="couse_video_box" v-if="courseStatus != 1">
          <!-- <div class="couse_video" v-if="courseStatus == 1">
            <img
              v-if="detail.course_live_temp"
              :src="detail.course_live_temp.image"
              alt=""
              srcset=""
            />
          </div> -->
          <div class="couse_video">
            <div class="quanping" @click="status1 = true">全屏</div>
            <iframe
              id="show-iframe"
              class="iframe_box"
              style="width: 100%; height: 100%"
              frameborder="0"
              scrolling="auto"
              :src="iframeUrl"
            ></iframe>
          </div>
          <!-- <div class="video_style flex_box flex_align_center flex_justify_center" v-if="detail.type == 1 && detail.is_order == 0">
            <div class="video_style_play"  @click="status = true">
              <img src="../assets/images/bofang.png" alt="">
            </div>
          </div> -->
        </div>
        <div
          class="couse_detail_info flex_box box_sizing flex_align_center flex_justify_between"
        >
          <div class="couse_info_left">
            <div class="couse_info_title overflow1">{{ detail.name }}</div>
            <div
              class="couse_info_list_box flex_box box_sizing flex_align_center flex_1"
            >
              <div
                class="couse_info_list_money flex_box flex_align_center"
                v-if="detail.type == 1"
              >
                ¥{{ detail.price }} <span>¥{{ detail.line_price }}</span>
              </div>
              <div
                class="couse_info_list_money flex_box flex_align_center"
                v-if="detail.type == 2"
              >
                免费 <span>¥{{ detail.line_price }}</span>
              </div>
              <div class="couse_info_time flex_box flex_align_center">
                <div class="couse_info_list_icon">
                  <img src="../assets/images/kecheng_time.png" alt="" />
                </div>
                <div
                  class="couse_info_list_time"
                  v-if="detail.course_live_temp"
                >
                  开课时间：{{ detail.course_live_temp.c_time_text }}
                </div>
              </div>
              <!-- <div class="couse_info_time flex_box flex_align_center">
                <div class="couse_info_list_icon">
                  <img src="../assets/images/baomingrenshu.png" alt="" />
                </div>
                <div class="couse_info_list_time">
                  报名人数：{{ detail.number }}人
                </div>
              </div> -->
            </div>
          </div>

          <div class="couse_info_right flex_box flex_diection justify_end">
            <div class="couse_info_btns flex_box flex_align_center justify_end">
              <div
                class="couse_info_btn now flex_box flex_align_center flex_justify_center"
                v-if="detail.type == 1 && detail.is_order == 0"
                @click="cousePay"
              >
                <div class="couse_info_btn_icon now">
                  <img src="../assets/images/goumai.png" alt="" />
                </div>
                <div class="couse_info_btn_txt">立即购买</div>
              </div>
              <!-- <div class="couse_info_btn flex_box flex_align_center flex_justify_center" @click="showIframe">
                <div class="couse_info_btn_txt">进入直播</div>
                  <div class="couse_info_btn_icon">
                    <img src="../assets/images/jinru.png" alt="">
                  </div>
                
              </div> -->
            </div>
            <div class="couse_kc_time" v-if="isShow && detail.type == 1">
              <span class="now">距离课程开始还有 </span>
              <span>{{ d }}</span> 天 <span>{{ h }}</span> 小时
              <span>{{ m }}</span> 分
            </div>
          </div>
        </div>

        <!-- 课程目录 -->
        <div
          class="mulu_box flex_box flex_align_center box_sizing"
          v-if="list.length > 0"
        >
          <div
            class="mulu_list flex_shrink flex_white_space mulu_flex_basis"
            v-for="(item, index) in list"
            :key="'nulu' + index"
            @click="muluChange(item, index)"
          >
            <div class="mulu_list_img">
              <img :src="item.image" alt="" />
            </div>
            、
            <div
              class="mulu_list_title"
              :class="[muluIndex == index ? 'now' : '']"
            >
              {{ index + 1 }}.{{ item.title }}
            </div>
          </div>
        </div>

        <!-- 课程详情 -->
        <div class="couse_xiangqing_wrap flex_box">
          <div class="couse_xiangqing_left box_sizing">
            <div
              class="couse_xiangqing_top box_sizing flex_box flex_justify_center"
            >
              <div
                class="xiangqing_top_list flex_box box_sizing flex_justify_between flex_diection flex_align_center"
                @click="scrollCommonDetails(1)"
              >
                <div
                  id="basic_info"
                  class="xiangqing_top_list_txt"
                  :class="{ now: nav == 1 }"
                >
                  基本信息
                </div>
                <div class="xiangqing_top_list_line" v-if="nav == 1"></div>
              </div>
              <!-- <div
                class="xiangqing_top_list flex_box box_sizing flex_justify_between flex_diection flex_align_center"
                v-if="detail.type == 1"
                @click="scrollCommonDetails(2)"
              > -->
              <div
                class="xiangqing_top_list flex_box box_sizing flex_justify_between flex_diection flex_align_center"
                @click="scrollCommonDetails(2)"
              >
                <div class="xiangqing_top_list_txt" :class="{ now: nav == 2 }">
                  评论
                </div>
                <div class="xiangqing_top_list_line" v-if="nav == 2"></div>
              </div>
            </div>

            <div class="liucheng_top box_sizing flex_box flex_align_center">
              <div class="liucheng_top_icon">
                <img src="../assets/images/liucheng_icon.png" alt="" />
              </div>
              <div class="liucheng_top_txt">我们提供的服务</div>
            </div>
            <div class="liucheng_img_box box_sizing flex_box flex_align_center">
              <div class="liucheng_img">
                <img src="../assets/images/liucheng0.png" alt="" />
                <img
                  class="liucheng_jiantou"
                  src="../assets/images/liuchengjiantou.png"
                  alt=""
                />
              </div>
              <div class="liucheng_img">
                <img src="../assets/images/liucheng1.png" alt="" />
                <img
                  class="liucheng_jiantou"
                  src="../assets/images/liuchengjiantou.png"
                  alt=""
                />
              </div>
              <div class="liucheng_img">
                <img src="../assets/images/liucheng2.png" alt="" />
                <img
                  class="liucheng_jiantou"
                  src="../assets/images/liuchengjiantou.png"
                  alt=""
                />
              </div>
              <div class="liucheng_img">
                <img src="../assets/images/liucheng3.png" alt="" />
                <img
                  class="liucheng_jiantou"
                  src="../assets/images/liuchengjiantou.png"
                  alt=""
                />
              </div>
              <div class="liucheng_img">
                <img src="../assets/images/liucheng4.png" alt="" />
              </div>
            </div>

            <div class="liucheng_top box_sizing flex_box flex_align_center">
              <div class="liucheng_top_icon">
                <img src="../assets/images/liucheng_icon.png" alt="" />
              </div>
              <div class="liucheng_top_txt">课程详情</div>
            </div>

            <div
              class="kecheng_detail box_sizing"
              v-html="detail.content"
            ></div>

            <div class="liucheng_top box_sizing flex_box flex_align_center">
              <div class="liucheng_top_icon">
                <img src="../assets/images/liucheng_icon.png" alt="" />
              </div>
              <div class="liucheng_top_txt" id="project_common">课程评论</div>
            </div>
            <template v-if="detail.type == 1">
              <div class="pinglun_box box_sizing">
                <div
                  class="pinglun_list box_sizing flex_box"
                  v-for="(item, index) in commentList"
                  :key="'comment' + index"
                >
                  <div class="pinglun_list_icon">
                    <img :src="item.user.avatar" alt="" />
                  </div>
                  <div class="box_sizing flex_1">
                    <div class="pinglun_right_top">
                      <div
                        class="pinglun_right_top_flex flex_box flex_align_center"
                      >
                        <div class="pinglun_right_name">
                          {{ item.nickname }}
                        </div>
                        <div class="flex_box flex_align_center">
                          <div
                            class="pinglun_right_star"
                            v-for="(item, index) in item.score"
                            :key="'star' + index"
                          >
                            <img src="../assets/images/star.png" alt="" />
                          </div>
                        </div>
                      </div>
                      <div class="pinglun_right_time">
                        {{ item.createtime }}
                      </div>
                    </div>
                    <div class="pinglun_right_content">{{ item.content }}</div>
                  </div>
                </div>
              </div>

              <div class="pinglun_bottom box_sizing" v-if="total > 5">
                <el-pagination
                  background
                  :page-size="5"
                  layout="prev, pager, next"
                  @current-change="pageChange"
                  :total="total"
                >
                </el-pagination>
              </div>
            </template>
            <div class="no_common">暂无评论</div>
          </div>

          <div class="couse_detail_right flex_1" v-if="detail.lecturer">
            <div class="lecturer_info box_sizing">
              <div
                class="lecturer_info_top box_sizing flex_box flex_align_center"
              >
                <div class="liucheng_top_icon">
                  <img src="../assets/images/liucheng_icon.png" alt="" />
                </div>
                <div class="liucheng_top_txt">讲师介绍</div>
              </div>

              <div class="lecturer_user box_sizing flex_box flex_align_center">
                <div class="lecturer_user_img">
                  <img :src="detail.lecturer.avatar" alt="" />
                </div>
                <div class="lecturer_user_right">
                  <div class="lecturer_user_name">
                    {{ detail.lecturer.name }}
                  </div>
                  <div class="lecturer_user_leve">
                    {{ detail.lecturer.label }}
                  </div>
                </div>
              </div>

              <div class="lecturer_content">{{ detail.lecturer.content }}</div>
            </div>

            <div class="tuijian_box flex_1" v-if="tuijianList.length != 0">
              <div
                class="lecturer_info_top box_sizing flex_box flex_align_center"
              >
                <div class="liucheng_top_icon">
                  <img src="../assets/images/liucheng_icon.png" alt="" />
                </div>
                <div class="liucheng_top_txt">推荐课程</div>
              </div>
              <div
                class="tuijian_list flex_box"
                v-for="(item, index) in tuijianList"
                :key="'tuijian' + index"
                @click="jumpDetail(item.id)"
              >
                <div class="tuijian_img">
                  <img :src="item.image" alt="" />
                </div>
                <div
                  class="flex_1 flex_diection flex_box flex_justify_between tuijian_list_right"
                >
                  <div class="tuijian_list_title overflow2">
                    {{ item.name }}
                  </div>
                  <div
                    class="tuijian_list_bottom flex_box flex_justify_between flex_align_end"
                  >
                    <div class="tuijian_list_bable" v-if="item.type == 2">
                      {{ item.price }}
                    </div>
                    <div class="tuijian_list_bable" v-else>
                      ￥{{ item.price }}
                    </div>
                    <!-- <div class="tuijian_bottom_baoming">
                      报名人数：<span>{{ item.number }}</span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <footers></footers> -->

    <div class="pay_wrap" v-if="status">
      <div class="pay_box box_sizing flex_box flex_diection flex_align_center">
        <div class="pay_close_btn" @click="status = false">
          <img src="../assets/images/login_close.png" alt="" />
        </div>
        <div class="pay_type">类目：购买课程</div>
        <div class="pay_type now">
          金额：<span>{{ detail.price }}</span
          >元
        </div>
        <div class="pay_img">
          <img :src="codeUrl" alt="" />
        </div>
        <div class="pay_txt">使用微信扫一扫支付</div>
        <div class="pay_btn" @click="jumpPayResult">已支付</div>
        <div class="pay_no_btn" @click="status = false">暂不支付</div>
      </div>
    </div>

    <!-- 全屏回放 -->
    <div class="pay_wrap" style="background: #fff" v-if="status1">
      <div class="pay_close_btn" style="z-index: 222" @click="status1 = false">
        <img src="../assets/images/login_close.png" alt="" />
      </div>
      <iframe
        id="show-iframe1"
        class="iframe_box"
        style="width: 100%; height: 100%"
        frameborder="0"
        scrolling="auto"
        :src="iframeUrl"
      ></iframe>
    </div>
  </div>
</template>

<script>
import headers from "@/components/headers";
// import footers from "@/components/footders";
import left from "@/components/left";
// import right from "@/components/right";
import scrollpage from "@/components/scrollpage";

import "@/css/couse_detail.css";
var _this;
export default {
  components: {
    headers,
    // footers,
    left,
    // right,
    scrollpage,
  },
  data() {
    return {
      status1: false,
      courseStatus: 1,
      iframeState: false,
      goBackState: false,
      iframeUrl: "",
      content: "",
      detail: {},
      status: false,
      tuijianList: [],
      total: 0,
      nav: 1,
      id: "",
      list: [],
      muluIndex: -1,
      page: 1,
      commentList: [],
      d: 0,
      h: 0,
      m: 0,
      isShow: true,
      orderNum: "",
      image: "",
      codeUrl: "",
    };
  },
  created() {
    _this = this;
    _this.getParams();
    _this.getTuijian();
  },
  mounted() {},
  methods: {
    // 全屏查看
    jumpQuanping() {
      window.open(_this.iframeUrl, "_blank");
    },

    // 课程跳转详情
    jumpDetail(id) {
      console.log(id);
      _this.id = id;
      _this.courseStatus = 1;
      _this.getDetail();
      _this.getCourseComment();
    },

    // 获取支付的二维码
    getPay() {
      let d = {
        order_num: _this.orderNum,
      };
      _this.$axios
        .post("course.course_order/payOrder", d)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          _this.$message.error(err.msg);
        });
    },

    // 购买课程
    cousePay() {
      let d = {
        course_id: _this.id,
      };
      if (localStorage.getItem("token")) {
        _this.$axios
          .post("course.course_order/createOrder", d)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.orderNum = res.data.order_num;
              _this.codeUrl =
                "http://yijishen.t.brotop.cn/api/course.course_order/payOrder?order_num=" +
                _this.orderNum +
                "&token=" +
                localStorage.getItem("token");
              _this.status = true;
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },

    // 改变页码时
    pageChange(page) {
      _this.page = page;
      _this.getCourseComment();
    },

    // 获取课程评价
    getCourseComment() {
      let d = {
        course_id: _this.id,
        page: _this.page,
        per_page: 5,
      };
      _this.$axios
        .post("course.course_comment/getCourseCommentList", d)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.commentList = res.data.data;
            _this.total = res.data.total;
          } else {
            _this.$message.error(res.msg);
          }
          _this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取推荐课程
    getTuijian() {
      let d = {
        limit: 10,
      };

      _this.$axios
        .post("course.course/getCourseRandList", d)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.tuijianList = res.data;
          } else {
            _this.$message.error(res.msg);
          }
          _this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 切换目录
    muluChange(item, i) {
      _this.muluIndex = i;
      if (localStorage.getItem("token")) {
        if (_this.detail.type == 1 && _this.detail.is_order == 0) {
          _this.$message.error("您还没购买此课程，暂时无法查看");
        } else {
          if (item.status == 1 && item.web_room_url != "") {
            window.open(item.web_room_url, "_blank");
          } else if (item.status == 2) {
            _this.$message.error("直播尚未开始，请等待");
          } else if (item.status == 3 && item.playback_url != "") {
            _this.courseStatus = 2;
            _this.iframeUrl = item.playback_url;
          } else if (item.status == 3 && item.playback_url == "") {
            _this.$message.error("暂无回放");
          }
        }
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },

    // 获取课程目录列表
    getMenuList() {
      var d = {
        course_id: _this.id,
      };
      _this.$axios
        .post("course.course_video/getCourseVideoList", d)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.list = res.data;
            _this.muluIndex = 0;
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 倒计时
    countTime() {
      var tmp = new Date().getTime(); // 当前时间
      var end = new Date(_this.detail.course_live_temp.c_time_text).getTime();

      //时间差
      var leftTime = end - tmp;
      console.log(leftTime);

      //定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        _this.d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        _this.h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        _this.m = Math.floor((leftTime / 1000 / 60) % 60);
      } else {
        _this.isShow = false;
      }
    },

    // 获取课程详情
    getDetail() {
      var d = {
        course_id: _this.id,
      };
      _this.$axios
        .post("course.course/getCourseInfo", d)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.detail = res.data;
            _this.list = res.data.course_live;
            _this.countTime();
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 跳转支付结果页面
    jumpPayResult() {
      var d = {
        order_num: _this.orderNum,
      };

      _this.$axios
        .post("course.course_order/checkOrder", d)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.$router.push({
              path: "/paySuccess",
              query: {},
            });
          } else {
            _this.$router.push({
              path: "/payFail",
              query: {},
            });
          }
        })
        .catch((err) => {
          console.log(err);
          _this.$router.push({
            path: "/payFail",
            query: {},
          });
        });
    },

    // 获取传递过来的参数
    getParams() {
      _this.id = _this.$route.query.id;
      _this.getDetail();
      _this.getCourseComment();
    },
    // 点击评论滑动
    scrollCommonDetails(type) {
      this.nav = type;
      let top = 0;
      if (type === 1) top = document.getElementById("basic_info").offsetTop;
      else top = document.getElementById("project_common").offsetTop;
      console.log(document.getElementById("project_common").style);
      window.scrollTo({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>


<style scoped>
.no_common{
  padding-left: 27px;
  font-size: 30px;
  color: rgba(6, 18, 30, 1);
}
</style>
<style>
.el-pagination.is-background .el-pager li:not(.disabled) {
  background: transparent;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: transparent;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(246, 112, 75, 1);
  color: #fff;
  border-radius: 50%;
}
.el-pagination.is-background .el-pager li.active {
  color: #fff;
  cursor: default;
  border-radius: 50%;
}
.el-pagination.is-background .el-pager li:hover {
  color: rgba(246, 112, 75, 1);
  border-radius: 50%;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: rgba(246, 112, 75, 1);
  border-radius: 50%;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  background-color: rgba(246, 112, 75, 1);
  border-radius: 50%;
  color: #fff;
}
</style>











