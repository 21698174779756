<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>

      <div class="question_list_wrap box_sizing">


        <div class="question_type_box box_sizing flex_box flex_align_center flex_justify_between">
          <navtitle :topic_id="id" title="段落排序" pinyin="RO" page="questionType10"></navtitle>

        </div>

        <div class="topic_wrap">
          <div class="topic_type_name">{{ detail.name }}</div>
          <div class="topic_type_info box_sizing flex_box flex_align_center">
            <div class="topic_type_info_time">{{ detail.createtime_text }}</div>
            <div class="question_lian_shoucang">
              <img
                class="question_list_jiangjieimg"
                v-if="!detail.collect || detail.collect == 1"
                src="../../assets/images/shoucang1.png"
                alt=""
              />
              <img
                class="question_list_jiangjieimg"
                v-if="detail.collect == 2"
                src="../../assets/images/shoucang2.png"
                alt=""
              />
              <img
                class="question_list_jiangjieimg"
                v-if="detail.collect == 3"
                src="../../assets/images/shoucang3.png"
                alt=""
              />
              <img
                class="question_list_jiangjieimg"
                v-if="detail.collect == 4"
                src="../../assets/images/shoucang4.png"
                alt=""
              />
              <img
                class="question_list_jiangjieimg"
                v-if="detail.collect == 5"
                src="../../assets/images/shoucang5.png"
                alt=""
              />
            </div>
            <!-- <img
              src="../../assets/images/type_fenxiang.png"
              class="topic_type_info_fenxiang"
              @click="copyDomain()"
              alt=""
            /> -->
          </div>
          <div class="topic_type_title">{{ detail.describe }}</div>

          <!-- 题目区域 -->
          <!-- <div class="topic_type_timu_box box_sizing">
            <span v-for="(item, index) in detail.show_text" :key="index"
              >{{ item }}&nbsp;</span
            >
          </div> -->
          <!-- <div class="my_padding box_sizing"> -->
          <!-- <span v-for="(item, index) in detail.show_text" :key="index">{{item}}&nbsp;</span> -->
          <!-- <div class="audio_inp flex_box">
              <div class="audio_left flex_box">
                <img
                  @click="playAudioTopic"
                  v-if="playStatus"
                  :src="zantingUrl"
                />
                <img @click="playAudioTopic" v-else :src="playUrl" />
                <div
                  class="audio_l_right box_sizing flex_box flex_align_center"
                > -->
          <!-- <div class="audio_lr_title box_sizing">
                    Status: Beginning in 40 seconds
                  </div> -->
          <!-- <div class="audio_lr_jindu flex_box"> -->
          <!-- <div class="topic_type1_luyin my_xiahuaxian">
                      <div class="topic_type1_luyin1" :style="'width: ' + len + '%'"></div>
                    </div> -->
          <!-- <div class="my_time box_sizing">{{ timeLength }}</div>
                  </div>
                </div>
              </div> -->
          <!-- <div class="audio_right flex_box">
                  <img src="../../assets/images/yinliang_icon@2x.png" />
                  <div class="audio_lr_jindu flex_box">
                    <div class="my_audio">
                      <div class="topic_type1_luyin1 my_type" style="width: 50%">
                          <img src="../../assets/images/huadong_icon@2x.png"/>
                      </div>
                    </div>
                  </div>
              </div> -->
          <!-- </div>
          </div> -->

          <!-- 答案区 -->
          <!-- <div class="topic_type_answer_box box_sizing"></div> -->
          <!-- <div
            class="dati_time box_sizing flex_box flex_align_center justify_end"
            v-if="daojishiTime > 0"
          >
            {{ daojishiTime }}s后开始答题
          </div> -->
          <div
            class="dati_time box_sizing flex_box flex_align_center justify_end"
          >
            答题时间：{{ datiTime }}
          </div>

          <div class="topic_type_answer_box box_sizing">
            <div class="flex_align_center flex_box" style="height: 100%">
              <div class="topic_type_answer_box box_sizing">
                <div class="title17">Source</div>
                <div
                  class="flex_box flex_align_center box_sizing choose17"
                  v-for="(item, index) in choose"
                  :key="index"
                  :class="chooseOne == index ? 'choose' : ''"
                  draggable="true"
                  @drag="dragDiv"
                  @dragover="dropOver($event)"
                  :data-text="item"
                  @dragstart="(e) => dragstartHandler(e, index)"
                >
                  <div class="p17">{{ item.show_index }}</div>
                  <div :class="chooseOne == index ? 'chooseP' : ''">
                    {{ item.content }}
                  </div>
                </div>
              </div>
              <img
                src="../../assets/images/choose.png"
                alt=""
                @click="exchange"
                style="width: 44px; height: 44px; margin: 0 15px"
              />
              <div
                class="topic_type_answer_box box_sizing"
                style="position: relative; height: 100%"
                @dragenter="deagenterDiv"
                @dragover="dragoverDiv"
                @drop="dropDiv"
              >
                <div class="title17">Correct Answer</div>
                <transition-group name="flip-list">
                  <div
                    class="flex_box flex_align_center box_sizing choose17"
                    v-for="(item, index) in rightChoose"
                    :key="index"
                    :class="chooseTwo == index ? 'choose' : ''"
                    draggable="true"
                    @dragstart="dragstart(item)"
                    @dragenter="dragenter(item)"
                    @dragend="dragend(item)"
                  >
                    <div class="p17">{{ item.show_index }}</div>
                    <div :class="chooseTwo == index ? 'chooseP' : ''">
                      {{ item.content }}
                    </div>
                  </div>
                  <!-- <div v-for="item in items1" :key="item" draggable="true" class="items" @dragstart="dragstart(item)" @dragenter="dragenter(item)" @dragend="dragend(item)">{{item}}</div> -->
                </transition-group>

                <!-- <div class="pic17">
                  <img
                    src="../../assets/images/shang.png"
                    alt=""
                    @click="chooseUp()"
                  />
                  <img
                    src="../../assets/images/xia.png"
                    alt=""
                    @click="chooseDown()"
                  />
                </div> -->
              </div>
            </div>
            <!-- <div class="topic_type_answer_title">Recorded Answer</div>
            <div class="topic_type_tt">Curret Status</div>
            <div class="topic_type_ttred">Recording…</div> -->

            <!-- <div class="topic_type1_luyin">
              <div class="topic_type1_luyin1" style="width: 10%"></div>
            </div>
            <div
              class="topci_type1_luyin_btnbox box_sizing flex_box flex_align_center flex_justify_center"
            >
              <img
                class="topci_type1_luyin_btn"
                src="../../assets/images/luyin_icon.png"
                alt=""
              />
            </div> -->
          </div>

          <div
            class="topic_type_answer_bottom box_sizing flex_box flex_align_center flex_justify_between"
          >
            <div
              class="topic_type_answer_botleft box_sizing flex_box flex_align_center"
            >
              <div class="topic_type_answer_botbtn" @click="submitTopic">
                完成
              </div>
              <div class="topic_type_answer_botbtn now" @click="chongzuo">
                重做
              </div>
              <div class="">
                <span style="margin-right: 3px">答案：</span>
                <el-tooltip content="解析答案">
                  <el-switch v-model="showSwitch" active-color="#f6704b"	></el-switch>
                </el-tooltip>
              </div>
              <!-- <div
                class="topic_type_answer_swich box_sizing flex_box flex_align_center"
              >
                <img
                  class="topic_type_answer_swich_img"
                  src="../../assets/images/shunxuzheng.png"
                  alt=""
                />
                <div class="topic_type_answer_swich_txt">顺序</div>
              </div> -->
            </div>
            <div
              class="topic_type_answer_botcenter box_sizing flex_box flex_align_center"
            >
              <div
                @click="seeDaFen"
                class="topic_type_answer_botcenter_list box_sizing flex_box flex_align_center"
              >
                <img
                  src="../../assets/images/dafen_icon.png"
                  class="topic_type_answer_botcenter_img"
                  alt=""
                />
                <div class="topic_type_answer_botcenter_txt">查看打分</div>
              </div>
              <div
                @click="shenQingPiGai"
                class="topic_type_answer_botcenter_list box_sizing flex_box flex_align_center"
              >
                <img
                  src="../../assets/images/shenqing_icon.png"
                  class="topic_type_answer_botcenter_img"
                  alt=""
                />
                <div class="topic_type_answer_botcenter_txt">申请批改</div>
              </div>
            </div>
            <div
              class="topic_type_answer_botright box_sizing flex_box flex_align_center"
            >
              <div class="topic_type_answer_botbtn tihao" @click="shangYiTi">上一题</div>
              <div class="topic_type_answer_botbtn" @click="xiaYiTi">
                下一题
              </div>
            </div>
          </div>
          <div v-show="showSwitch" class="common_content">
            <!-- <div style="font-size: 18px;margin-bottom: 5px">答案：</div> -->
            <div v-html="detail.show_tape_text"></div>
          </div>
        </div>
      </div>
    </div>

    <footers></footers>

    <!-- 弹框 -->
    <div class="model" v-if="status">
      <div class="modelContent box_sizing">
        <div class="title">{{ detail.topic_type_name }}</div>
        <!-- <div class="teacher flex_box flex_align_center box_sizing flex_justify_between">
                    <div class="flex_box flex_align_center" style="width: 40%;">
                        <img src="../../assets/images/play.png" alt="" >
                        <div classRead="play box_sizing">
                            <div class="topic_type1_luyin progress">
                                <div class="topic_type1_luyin1" style="width: 50%"></div>
                            </div>
                            <div class="time">00:01/01:23</div>
                        </div>
                    </div>
                    <div class="flex_align_center flex_box yin box_sizing" >
                        <img src="../../assets/images/laba.png" alt="" >
                        <div class="topic_type1_luyin progress">
                            <div class="topic_type1_luyin1" style="width: 50%"></div>
                        </div>
                    </div>

                </div> -->
        <div class="flex-warp flex_box onebox">
          <div
            class="one flex_box flex_align_center box_sizing flex-wrap"
            v-if="obj.content_score"
          >
            <div class="neirong flex_box">内容</div>
            <div class="fenshu">
              <div>得分：{{ obj.content_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.content_score.full }}分
              </div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.format_score"
          >
            <div class="neirong flex_box">格式</div>
            <div class="fenshu">
              <div>得分：{{ obj.format_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.format_score.full }}分
              </div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.structure"
          >
            <div class="neirong flex_box">发展结构</div>
            <div class="fenshu">
              <div>得分：{{ obj.structure.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.structure.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.grammar"
          >
            <div class="neirong flex_box">语法</div>
            <div class="fenshu">
              <div>得分：{{ obj.grammar.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.grammar.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.structure"
          >
            <div class="neirong flex_box">语言范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.structure.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.structure.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.wordScore"
          >
            <div class="neirong flex_box">词汇范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.wordScore.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.wordScore.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.spelling"
          >
            <div class="neirong flex_box">拼写</div>
            <div class="fenshu">
              <div>得分：{{ obj.spelling.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.spelling.full }}分</div>
            </div>
          </div>

          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.score || obj.score == 0"
          >
            <div class="fenshu">
              <div>得分：{{ obj.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.full }}分</div>
            </div>
          </div>
        </div>
        <div class="zuoda box_sizing">
          <div class="zuodatitle box_sizing">作答内容</div>
          <div class="" style="margin-bottom: 50px; width: 100%; font-size: 16px;line-height: 26px;">
            <div v-for="(item, index) in obj.content" :key="index">
              【{{ index + 1 }}】{{ item ? item : "空" }} &nbsp;
            </div>
          </div>
        </div>
        <!--          <div class="zuoda box_sizing" style="padding-top: 20px">-->
        <!--          <div class="zuodatitle box_sizing">正确答案</div>-->
        <!--           <div class="zuodacont" style="margin-bottom: 50px">-->
        <!--            <div v-for="(item,index) in obj.return" :key="index">-->
        <!--            {{ item ? item : "空" }} &nbsp;-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!-- <input
            type="button"
            class="btn-record-voice"
            @click="playAudio"
            v-model="bofangTxt"
          /> -->

        <div class="cancel" @click="status = false">
          <img src="../../assets/images/login_close.png" alt="" />
        </div>

        <!-- <div class="ping">
                    <img src="../../assets/images/wenhao.png" alt="">
                    <div>评分细则</div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
import "@/css/questionList.css";
import "@/css/questiontype.css";
import "@/css/pagePing.css";
import Navtitle from "@/components/navtitle.vue";

var _this, intervalTime;
export default {
  components: {
    Navtitle,
    headers,
    footers,
    left,
    right,
    scrollpage,
  },
  data() {
    return {
      showSwitch:false,
      topicExerciseId: null,
      oldNum: 0,
      newNum: 0,
      isLeft: true,
      len: 0,
      id: "",
      detail: {},
      choose: [],
      rightChoose: [],
      chooseOne: -1,
      chooseTwo: -1,
      interval: "",
      obj: {},
      huida: false,
      status: false,
      title: "",
      time: 0,
      datiTime: "00:00:00",
    };
  },
  destroyed() {
    clearInterval(intervalTime);
  },
  created() {
    _this = this;
    _this.getParams();
    _this.daojishi();
    // _this.daojishiBack();
  },
  mounted() {},
  methods: {
    copyDomain() {
      if (_this.topicExerciseId == null) {
        this.$message.error("请申请批改后复制链接");
        return;
      }
      _this.$copyText(_this.$global.baseUrl + "&id=" + _this.topicExerciseId);
      this.$message.success("地址复制成功");
    },
    // 进入页面倒计时40秒开始录音
    daojishiBack() {
      let daojishiBo = setInterval(() => {
        if (_this.daojishiTime <= 0) {
          clearInterval(daojishiBo);
          _this.audio.src = require("../../assets/di.mp3");
          let playPromise;
          playPromise = _this.audio.play();
          _this.playStatus = true;
          if (playPromise) {
            playPromise
              .then(() => {
                // 音频加载成功
                // 音频的播放需要耗时
              })
              .catch((e) => {
                // 音频加载失败
                console.error(e);
              });
          }
          _this.getTime();
        } else {
          _this.daojishiTime--;
        }
      }, 1000);
    },

    // 返回上一页
    goBack() {
      _this.$router.back();
    },

    // 在目标元素中移动
    dragoverDiv(e) {
      console.log("555555555555555555555555555555555555555");
      // console.log(e);
      e.dataTransfer.dropEffect = "copy";
      e.preventDefault();
    },

    // 正在拖动
    dragDiv(e) {
      // console.log('正在拖动', e)
    },

    // 阻止默认事件
    dropOver(e) {
      e.preventDefault(); // 在拖动中阻止默认事件
    },

    // 设置拖拽元素的方式
    deagenterDiv(e) {
      console.log("444444444444444444");
      // console.log(e)
      e.dataTransfer.dropEffect = "copy";
      e.preventDefault();
    },

    // 开始拖拽
    dragstartHandler(e, i) {
      console.log("1212121212222222222222222222拖拽");
      console.log("拖拽索引", i);
      _this.isLeft = true;
      // console.log(e.target.dataset.text);
      e.dataTransfer.effectAllowed = "copy";
      e.dataTransfer.setData("copyKey", i);
      // 获取拖拽的元素
    },

    // 拖拽到此
    dropDiv(e) {
      console.log("1111111111111111111111111111111111111释放");
      console.log(e);

      if (_this.isLeft) {
        _this.rightChoose.push(_this.choose[e.dataTransfer.getData("copyKey")]);
        _this.choose.splice(e.dataTransfer.getData("copyKey"), 1);
      }

      // 将拖动元素旋转到目标区域中
      // var copyKey = e.dataTransfer.getData('copyKey')
      // console.log(copyKey);
      // _this.daanObj[e.target.dataset.txt] = copyKey;
      // _this.$for
    },

    shuffle: function () {
      this.rightChoose = _.shuffle(this.rightChoose);
    },
    // 记录初始信息
    dragstart: function (value) {
      this.oldNum = value;

      _this.isLeft = false;
    },
    // 做最终操作
    dragend: function (value) {
      if (this.oldNum != this.newNum) {
        let oldIndex = this.rightChoose.indexOf(this.oldNum);
        let newIndex = this.rightChoose.indexOf(this.newNum);
        let newItems = [...this.rightChoose];
        // 删除老的节点
        newItems.splice(oldIndex, 1);
        // 在列表中目标位置增加新的节点
        newItems.splice(newIndex, 0, this.oldNum);
        // this.items一改变，transition-group就起了作用
        this.rightChoose = [...newItems];
      }
    },
    // 记录移动过程中信息
    dragenter: function (value) {
      this.newNum = value;
    },

    // 跳转更多讲解
    toMoreList() {
      // _this.$router.push({
      //     path: './moreList'
      // })
    },
    chooseSource(e) {
      //选择左边栏选项
      console.log(e);
      _this.chooseOne = e;
      _this.chooseTwo = -1;
    },
    chooseCorrect(e) {
      //选择右边栏选项
      console.log(e);
      _this.chooseTwo = e;
      _this.chooseOne = -1;
    },
    exchange() {
      console.log(_this.chooseOne);
      console.log(_this.chooseTwo);

      if (_this.chooseOne != -1) {
        var value = _this.choose[_this.chooseOne];
        _this.choose.splice(_this.chooseOne, 1);
        _this.rightChoose.push(value);
        _this.chooseOne = -1;
      } else if (_this.chooseTwo != -1) {
        var valueTwo = _this.rightChoose[_this.chooseTwo];
        _this.rightChoose.splice(_this.chooseTwo, 1);
        _this.choose.push(valueTwo);
        _this.chooseTwo = -1;
      } else {
        _this.$message.error("请选择要操作的选项！");
      }
    },
    chooseUp() {
      //上移
      var index = _this.chooseTwo;
      if (index != 0) {
        _this.rightChoose[index] = _this.rightChoose.splice(
          index - 1,
          1,
          _this.rightChoose[index]
        )[0];
      } else {
        _this.$message.error("已经是第一个数据了，不可以上移了");
      }
    },
    chooseDown() {
      //下移
      var index = _this.chooseTwo;
      if (index != _this.rightChoose.length - 1) {
        _this.rightChoose[index] = _this.rightChoose.splice(
          index + 1,
          1,
          _this.rightChoose[index]
        )[0];
      } else {
        _this.$message.error("已经是最后一个数据了，不可以下移了");
      }
    },

    // playAudioTopic() {

    //   if (_this.playStatus) {
    //    _this.audio.pause();
    //     _this.timeLength = _this.tiemrs;
    //     _this.playStatus = false;
    //     clearInterval(_this.tiemr);
    //     _this.audio.load();
    //   } else {
    //     _this.audio = new Audio();
    //   _this.audio.src = _this.detail.file;
    //   let playPromise,
    //     second = _this.detail.duration;
    //     playPromise = _this.audio.play();
    //     _this.playStatus = true;
    //     if (playPromise) {
    //       playPromise
    //         .then(() => {
    //           // 音频加载成功
    //           // 音频的播放需要耗时
    //           _this.tiemr = setInterval(() => {
    //             second--;
    //             _this.timeLength = _this.formateSeconds(second);
    //             if (second <= 0) {
    //               _this.audio.pause();
    //               _this.timeLength = _this.tiemrs;
    //               _this.playStatus = false;
    //               clearInterval(_this.tiemr);
    //             }
    //           }, 1000);
    //         })
    //         .catch((e) => {
    //           // 音频加载失败
    //           console.error(e);
    //         });
    //     }
    //   }
    // },

    //将秒转化为时分秒
    formateSeconds(endTime) {
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min > 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },

    // 上一题
    shangYiTi() {
      var params = {
        topic_id: _this.id,
        type: _this.detail.topic_type_id,
        exercise_status: localStorage.getItem('lianxiId'),
        topic_collect: localStorage.getItem('collectId'), //收藏 之前添加传的
        pinlvId: localStorage.getItem('pinlvId'),
        tags: localStorage.getItem('tag_id'),
        topic_lecture_id:
          localStorage.getItem('jiangjieId') == 0 ? undefined : localStorage.getItem('jiangjieId') == 1 ? 1 : 0, //名师讲解id 0=没有讲解
      };
      _this.$axios
        .post("exercise.topic/lastTopic", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.id = res.data.topic_id;
            console.log("当前的返回", res.data.topic_id);
            this.$router.push({
              path: "questionType10?id=" + res.data.topic_id + "&name=段落排序",
            });
            location.reload();
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          _this.$message.error(err.msg);
        });
    },

    // 下一题
    xiaYiTi() {
      var params = {
        topic_id: _this.id,
        type: _this.detail.topic_type_id,
        exercise_status: localStorage.getItem('lianxiId'),
        topic_collect: localStorage.getItem('collectId'), //收藏 之前添加传的
        pinlvId: localStorage.getItem('pinlvId'),
        tags: localStorage.getItem('tag_id'),
        topic_lecture_id:
          localStorage.getItem('jiangjieId') == 0 ? undefined : localStorage.getItem('jiangjieId') == 1 ? 1 : 0, //名师讲解id 0=没有讲解
      };
      _this.$axios
        .post("exercise.topic/nextTopic", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.id = res.data.topic_id;
            console.log("当前的返回", res.data.topic_id);
            this.$router.push({
              path: "questionType10?id=" + res.data.topic_id + "&name=段落排序",
            });
            location.reload();
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          _this.$message.error(err.msg);
        });
    },

    // 重做
    chongzuo() {
      location.reload()
      // _this.$forceUpdate()
      // _this.timeLen = 0;
      // _this.rightChoose = [];
      // _this.mouseStatus = false;
      // let obj = _this.form;
      // obj.time = "按住录音";
      // _this.form = obj;
      // _this.choose = JSON.parse(JSON.stringify(this.detail)).extend.extend_sort;
      // _this.detail = {}
      //
      // clearInterval(intervalTime);
      window.scrollTo(0, 0)

      // _this.getTime();
    },

    // 申请批改
    shenQingPiGai() {
      if (_this.huida) {
        var params = {
          topic_exercise_id: _this.topicExerciseId,
        };
        _this.$axios
          .post("exercise.topic_exercise/submit", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.$message.success("您已提交申请");
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
      } else {
        _this.$message.error("您还没答题，请先进行答题");
      }
    },

    // 查看打分
    seeDaFen() {
      if (_this.huida) {
        _this.status = true;
      } else {
        _this.$message.error("您还没答题，请先进行答题");
      }
    },
    // 开始计时
    daojishi() {
      intervalTime = setInterval(() => {
        _this.time = _this.time + 1;
        _this.datiTime = _this.formateSeconds(_this.time);
      }, 1000);
    },

    // 点击完成
    submitTopic() {
      let str = [];
      _this.rightChoose.forEach((item) => {
        str.push(item.index);
      });
      let params = {
        topic_id: _this.id,
        content: str.join(","),
        time: _this.time,
      };
      if (localStorage.getItem("token")) {
        if (_this.rightChoose.length != 0) {
          _this.$axios
            .post("exercise.topic/submit", params)
            .then((res) => {
              console.log(res);
              if (res.code == 1) {
                _this.obj = res.data;
                _this.topicExerciseId = res.data.topicExerciseId;
                _this.huida = true;
                _this.$message.success("答题已完成");
                _this.status = true;
                console.log(_this.status);
                clearInterval(intervalTime);
              } else {
                _this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
              _this.$message.error(err.msg);
            });
        } else {
          _this.$message.error("您还没答题，请先进行答题");
        }
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },

    // 播放录音
    // playAudio() {
    //   recorder.play();
    // },

    // // 上传音频
    // submitUp() {
    //   let PCMBlob = recorder.getWAVBlob(); //获取 PCM 数据
    //   console.log(PCMBlob);
    //   _this.mouseStatus = true;
    //   var formData = new FormData();
    //   formData.append("file", PCMBlob);
    //   var url = "common/uploadRecord";
    //   _this.$uploadFile
    //     .post(url, formData)
    //     .then(function (res) {
    //       console.log(res);
    //       _this.auUrl = res.data.url;
    //       console.log(_this.auUrl);
    //       // _this.$notify.success({
    //       //   title: "提示",
    //       //   message: "上传成功",
    //       //   duration: 1500,
    //       // });
    //     })
    //     .catch((err) => {
    //       // _this.$notify.error({
    //       //   title: "提示",
    //       //   message: "上传失败请重试",
    //       //   duration: 1500,
    //       // });
    //     });
    // },

    // // 长按说话
    // mouseStart() {
    //   console.log("11111111111111111");

    //   recorder.start(); // 开始录音
    //   _this.getTime();
    //   let obj = _this.form;
    //   obj.time = "松开结束";

    //   _this.form = obj;
    // },

    // // 松开时上传语音
    // mouseEnd() {
    //   console.log("3333333");
    //   recorder.stop(); // 结束录音

    //   // 手动获取录音时长

    //   console.log(recorder.duration);

    //   // recorder.play()
    //   _this.submitUp();
    // },

    // 获取时间
    getTime() {
      intervalTime = setInterval(() => {
        if (_this.timeLen < 40) {
          _this.timeLen = _this.timeLen + 1;
          _this.datiTime = _this.formateSeconds(_this.timeLen);
        } else {
          console.log("当前调用生效", "222222222222222222222222");

          clearInterval(intervalTime);
          _this.submitTopic();
        }
      }, 1000);
    },

    // 获取传递过来的参数
    getParams() {
      _this.id = _this.$route.query.id;
      console.log("1111111111111111");
      //   _this.title = _this.$route.query.name;
      _this.getDetail();
    },
    // 微信分享复制链接
    copyToH5(){

    // console.log('当前的domain', item);return
      let pinyin = 'RO';
      let tips = pinyin+" "+_this.detail.number+" "+" PTE易王炸AI评分 ";
      this.$copyText(tips+" "+window.location.origin+"/H5Detail?exericise_id="+_this.topicExerciseId+"&topic_id="+ _this.detail.id+"&pinyin="+pinyin+"&title="+encodeURIComponent(_this.detail.name)+"&page=questionType6");
      this.$message.success("地址复制成功")
    },

    // 获取详情
    getDetail() {
      var params = {
        topic_id: _this.id,
      };
      _this.$axios
        .post("exercise.topic/getTopicInfo", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.detail = res.data;

            _this.choose = JSON.parse(
              JSON.stringify(res.data)
            ).extend.extend_sort;
            // _this.getTime();
            // _this.timeLength = _this.formateSeconds(res.data.duration);
            // _this.tiemrs = _this.formateSeconds(res.data.duration);
            // console.log(_this.timeLength)
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>


<style>
.flip-list-move {
  transition: transform 1s;
}

.banner_detail {
  width: 100%;
  padding: 32px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 1);
  text-align: center;
}

/* 作答 */
.topic_type_answer_box {
  min-width: 420px;
  height: auto;
}
.topic_type_answer_box_h5 {
  width: 100%;
}

.grade18 {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 22px;
  color: #f6704b;
  padding-top: 53px;
}

.title17 {
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 30px;
  color: #8c9198;
}

.choose17 {
  width: 364px;
  height: 91px;
  background: #ffffff;
  opacity: 1;
  border-radius: 10px;
  padding: 16px;
  margin: 0 auto;
  font-size: 14px;
  margin-top: 20px;
}

.p17 {
  margin-right: 22px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 22px;
  color: #f6704b;
}

.pic17 {
  position: absolute;
  top: 50%;
  right: 11px;
  transform: translate(0, -50%);
}

.pic17 img {
  width: 24px;
  height: 24px;
  margin: 6px 0;
}

.choose {
  box-shadow: 0px 3px 12px #ebd0c3;
}

.chooseP {
  color: #f6704b;
}
</style>
