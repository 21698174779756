<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>
    <word ref="word"></word>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>

      <div class="question_list_wrap box_sizing">
        <!--                <el-button style="margin-bottom: 20px" @click="$router.go(-1)">返回</el-button>-->

<!--        <div class="question_type_box box_sizing flex_box flex_align_center flex_justify_between ">-->
<!--          <div class="question_list_left flex_1 flex_box flex_align_center">-->
<!--            <div-->
<!--              v-if="detail.topic_type_id == 6"-->
<!--              class="question_type_labels box_sizing"-->
<!--            >-->
<!--              WE-->
<!--            </div>-->
<!--            <div-->
<!--              v-if="detail.topic_type_id == 7"-->
<!--              class="question_type_labels box_sizing"-->
<!--            >-->
<!--              SWT-->
<!--            </div>-->
<!--            <div class="question_type_name">{{ detail.topic_type_name }}</div>-->
<!--            <div class="question_type_number">-->
<!--              {{ detail.count }}/{{ detail.current }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <img-->
<!--            alt="返回上一页"-->
<!--            class="question_type_right"-->
<!--            src="../../assets/images/guanbi.png"-->
<!--            @click="goBack"-->
<!--          />-->
<!--        </div>-->
        <div class="question_type_box box_sizing flex_box flex_align_center flex_justify_between" v-if="detail.topic_type_id == 6">
          <navtitle :topic_id="id" title="大作文" pinyin="WE" page="questionType6"></navtitle>
        </div>
        <div class="question_type_box box_sizing flex_box flex_align_center flex_justify_between" v-if="detail.topic_type_id == 7">
          <navtitle :topic_id="id" title="总结全文" pinyin="SWT" page="questionType6"></navtitle>
        </div>
        <div class="question_type_box box_sizing flex_box flex_align_center flex_justify_between" v-if="detail.topic_type_id == 23">
          <navtitle :topic_id="id" title="大作文（CORE）" pinyin="WE_C" page="questionType6"></navtitle>
        </div>

        <div class="topic_wrap">
          <div class="topic_type_name">{{ detail.name }}</div>
          <div class="topic_type_info box_sizing flex_box flex_align_center">
            <div class="topic_type_info_time">{{ detail.createtime_text }}</div>
            <div class="question_lian_shoucang">
              <img
                v-if="!detail.collect || detail.collect == 1"
                alt=""
                class="question_list_jiangjieimg"
                src="../../assets/images/shoucang1.png"
              />
              <img
                v-if="detail.collect == 2"
                alt=""
                class="question_list_jiangjieimg"
                src="../../assets/images/shoucang2.png"
              />
              <img
                v-if="detail.collect == 3"
                alt=""
                class="question_list_jiangjieimg"
                src="../../assets/images/shoucang3.png"
              />
              <img
                v-if="detail.collect == 4"
                alt=""
                class="question_list_jiangjieimg"
                src="../../assets/images/shoucang4.png"
              />
              <img
                v-if="detail.collect == 5"
                alt=""
                class="question_list_jiangjieimg"
                src="../../assets/images/shoucang5.png"
              />
            </div>
            <!-- <img
              alt=""
              class="topic_type_info_fenxiang"
              src="../../assets/images/type_fenxiang.png"
              @click="copyDomain()"
            /> -->
          </div>
          <div class="topic_type_title">{{ detail.describe }}</div>
          <div class="topic_type_timu_box box_sizing hand_change" v-if="detail.topic_type_id == 7">
            <span v-html="detail.show_text"></span>
          </div>
          <div class="topic_type_timu_box box_sizing hand_change" v-else>
            <span
              v-for="(item, index) in filteredShowText"
              :key="index"
              class="panding_change"
              @click="dianjiClick(item)"
              v-html="item"
            ></span>
          </div>

          <!-- <div
            class="dati_time box_sizing flex_box flex_align_center justify_end"
            v-if="daojishiTime > 0"
          >
            {{ daojishiTime }}s后开始答题
          </div> -->
          <div
            class="dati_time box_sizing flex_box flex_align_center justify_end"
          >
            答题时间：{{ datiTime }}
          </div>

          <div
            class="topic_type_answer_box box_sizing"
            style="height: auto"

          >
            <div
              class="topic_textarea_box"
            >
              <textarea v-model="content" placeholder="请作答" @keyup="computedWordCount()"></textarea>

            </div>
            <div
              class=""
            >
              Word：{{ wordCount }}

            </div>
            <!-- <div class="topic_textarea_btn_flex box_sizing flex_box flex_align_center flex_justify_between">
                            <div class="topic_textarea_left"></div>
                            <div class="topic_textarea_right box_sizing flex_box flex_align_center">
                                <div class="topic_textarea_right_btn">CUT</div>
                                <div class="topic_textarea_right_btn">COPY</div>
                                <div class="topic_textarea_right_btn">PASTE</div>
                            </div>
                        </div> -->
          </div>

          <!-- 所得分数 -->
          <!-- <div class="my_fen flex_box box_sizing">
                        <span>所得分数：1/3</span>
                        <span class="my_fen_l box_sizing">（满分3分）</span>
                    </div> -->

          <div
            class="
              topic_type_answer_bottom
              box_sizing
              flex_box flex_align_center flex_justify_between
            "
          >
            <div
              class="
                topic_type_answer_botleft
                box_sizing
                flex_box flex_align_center
              "
            >
              <div class="topic_type_answer_botbtn" @click="submitAns">
                完成
              </div>
              <div class="topic_type_answer_botbtn now" @click="chongzuo">
                重做
              </div>
              <!-- <div class="topic_type_answer_swich box_sizing flex_box flex_align_center">
                                <img class="topic_type_answer_swich_img" src="../../assets/images/shunxuzheng.png" alt="">
                                <div class="topic_type_answer_swich_txt">顺序</div>
                            </div> -->
              <div class="">
                <span style="margin-right: 3px">答案：</span>
                <el-tooltip content="解析答案">
                  <el-switch v-model="showSwitch" active-color="#f6704b"	></el-switch>
                </el-tooltip>
              </div>
            </div>
            <div
              class="
                topic_type_answer_botcenter
                box_sizing
                flex_box flex_align_center
              "
            >
              <div
                class="
                  topic_type_answer_botcenter_list
                  box_sizing
                  flex_box flex_align_center
                "
                @click="seeDaFen"
              >
                <img
                  alt=""
                  class="topic_type_answer_botcenter_img"
                  src="../../assets/images/dafen_icon.png"
                />
                <div class="topic_type_answer_botcenter_txt">查看打分</div>
              </div>
              <!--              <div-->
              <!--                class="-->
              <!--                  topic_type_answer_botcenter_list-->
              <!--                  box_sizing-->
              <!--                  flex_box flex_align_center-->
              <!--                "-->
              <!--                @click="shenQingPiGai"-->
              <!--              >-->
              <!--                <img-->
              <!--                  src="../../assets/images/shenqing_icon.png"-->
              <!--                  class="topic_type_answer_botcenter_img"-->
              <!--                  alt=""-->
              <!--                />-->
              <!--                <div class="topic_type_answer_botcenter_txt">申请批改</div>-->
              <!--              </div>-->
            </div>
            <!-- <div class="topic_type_answer_botright box_sizing flex_box flex_align_center">
                            <div class="topic_type_answer_botbtn tihao">上一题</div>
                            <div class="topic_type_answer_botbtn">下一题</div>
                        </div> -->
          </div>
        </div>
        <div v-show="showSwitch" class="common_content">
          <!-- <div style="font-size: 18px;margin-bottom: 5px">答案：</div> -->
          <div v-html="detail.show_tape_text"></div>
        </div>
      </div>
    </div>
    <footers></footers>
    <!-- 弹框 -->
    <div v-if="status" class="model">
      <div class="modelContent box_sizing">
        <div class="title">{{ detail.topic_type_name }}</div>
        <!-- <div class="teacher flex_box flex_align_center box_sizing flex_justify_between">
                    <div class="flex_box flex_align_center" style="width: 40%;">
                        <img src="../../assets/images/play.png" alt="" >
                        <div class="play box_sizing">
                            <div class="topic_type1_luyin progress">
                                <div class="topic_type1_luyin1" style="width: 50%"></div>
                            </div>
                            <div class="time">00:01/01:23</div>
                        </div>
                    </div>
                    <div class="flex_align_center flex_box yin box_sizing" >
                        <img src="../../assets/images/laba.png" alt="" >
                        <div class="topic_type1_luyin progress">
                            <div class="topic_type1_luyin1" style="width: 50%"></div>
                        </div>
                    </div>

                </div> -->
        <div class="flex-warp flex_box onebox">
          <div
            v-if="obj.content_score"
            class="one flex_box flex_align_center box_sizing flex-wrap"
          >
            <div class="neirong flex_box">内容</div>
            <div class="fenshu">
              <div>得分：{{ obj.content_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.content_score.full }}分
              </div>
            </div>
          </div>
          <!-- <div class="one flex_box flex_align_center box_sizing" v-if="obj.format_score">
                        <div class="neirong flex_box">格式</div>
                        <div class="fenshu ">
                            <div>得分：{{obj.format_score.score}}分</div>
                            <div style="color: #8C9198;">满分：{{obj.format_score.full}}分</div>
                        </div>
                    </div> -->
          <div
            v-if="obj.format_score"
            class="one flex_box flex_align_center box_sizing"
          >
            <div class="neirong flex_box">格式</div>
            <div class="fenshu">
              <div>得分：{{ obj.format_score.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.format_score.full }}分</div>
            </div>
          </div>
          <div
            v-if="obj.grammar"
            class="one flex_box flex_align_center box_sizing"
          >
            <div class="neirong flex_box">语法</div>
            <div class="fenshu">
              <div>得分：{{ obj.grammar.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.grammar.full }}分</div>
            </div>
          </div>
          <!-- <div class="one flex_box flex_align_center box_sizing" v-if="obj.structure">
                        <div class="neirong flex_box">语言范围</div>
                        <div class="fenshu ">
                            <div>得分：{{obj.structure.score}}分</div>
                            <div style="color: #8C9198;">满分：{{obj.structure.full}}分</div>
                        </div>
                    </div> -->
          <div
            v-if="obj.wordScore"
            class="one flex_box flex_align_center box_sizing"
          >
            <div class="neirong flex_box">词汇范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.wordScore.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.wordScore.full }}分</div>
            </div>
          </div>
          <div
            v-if="obj.spelling"
            class="one flex_box flex_align_center box_sizing"
          >
            <div class="neirong flex_box">拼写</div>
            <div class="fenshu">
              <div>得分：{{ obj.spelling.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.spelling.full }}分</div>
            </div>
          </div>


          <div
            v-if="obj.structure"
            class="one flex_box flex_align_center box_sizing"
          >
            <div class="neirong flex_box">发展结构</div>
            <div class="fenshu">
              <div>得分：{{ obj.structure.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.structure.full }}分</div>
            </div>
          </div>


          <div
            v-if="obj.email"
            class="one flex_box flex_align_center box_sizing"
          >
            <div class="neirong flex_box">邮件格式</div>
            <div class="fenshu">
              <div>得分：{{ obj.email.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.email.full }}分</div>
            </div>
          </div>


          <div
            v-if="obj.struct_score"
            class="one flex_box flex_align_center box_sizing"
          >
            <div class="neirong flex_box">语言范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.struct_score.struct_score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.struct_score.full }}分</div>
            </div>
          </div>
          <div
            v-if="obj.total"
            class="one flex_box flex_align_center box_sizing"
          >
            <div class="neirong flex_box">总分</div>
            <div class="fenshu">
              <div>得分：{{ obj.total.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.total.full }}分</div>
            </div>
          </div>

          <!-- <div class="one flex_box flex_align_center box_sizing" v-if="obj.spelling">
                        <div class="neirong flex_box">拼写</div>
                        <div class="fenshu ">
                            <div>得分：{{obj.spelling.score}}分</div>
                            <div style="color: #8C9198;">满分：{{obj.spelling.full}}分</div>
                        </div>
                    </div> -->
        </div>
        <div class="zuoda box_sizing">
          <div class="zuodatitle box_sizing" style="display: flex; align-items: center;">
            <div class="zuodaname">作答内容</div>
            <div class="question_lian_cishu"  @click="copyToH5()" title="复制链接，发送给好友，分享你的答案和评分结果">
                分享
              </div>
          </div>
          <div class="zuodacont" style="height: 200px !important;" v-html="obj.content"></div>
        </div>

        <div class="cancel" @click="status = false">
          <img alt="" src="../../assets/images/login_close.png" />
        </div>

        <!-- <div class="ping">
                    <img src="../../assets/images/wenhao.png" alt="">
                    <div>评分细则</div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
import "@/css/questionList.css";
import "@/css/questiontype.css";
import "@/css/pagePing.css";
import Recorder from "js-audio-recorder";
import Navtitle from "@/components/navtitle.vue";
import Word from "@/components/word.vue";

var recorder = new Recorder();
var _this, intervalTime;
export default {
  components: {
    Navtitle,
    headers,
    footers,
    left,
    right,
    scrollpage,
    Word
  },
  data() {
    return {
      topicExerciseId: null,
      showSwitch: false,

      wordCount:    0,
      id:           "",
      detail:       {},
      content:      "",
      time:         0,
      list:         [],
      status:       false,
      timeLen:      1200,
      obj:          {},
      huida:        false,
      show_text:    [
        "Since",
        "",
        "Australians",
        "",
        "Jennifer",
        "",
        "<br/><br/>",
        "Hawkins",
        "",
        "and",
        "",
        "Lauryn",
        "",
        "Eagle",
      ],
      datiTime:     "00:00:00",
      bofangStatus: false,
      daojishiTime: 40,
      audio:        null,
      UrlData:      ''
    };
  },
  computed: {
    filteredShowText() {
      return this.detail.show_text.filter(item => item !== '<br/>');
    }
  },
  created() {
    _this = this;
    _this.UrlData = this.$route.query;
    // 石墨文档规定rl音频播放完毕后的准备时间为10s,三元运算判断值
    _this.timeLen = _this.UrlData.name == "总结全文" ? 600 : (_this.UrlData.name == '总结全文' ? 1200 : 540);
    _this.getParams();
    // _this.daojishiBack();
  },
  destroyed() {
    clearInterval(intervalTime)
  },
  methods: {
    computedWordCount() {
      let value = _this.content;

      // 替换中文字符为空格
      value = value.replace(/[\u4e00-\u9fa5]+/g, " ");
      // 将换行符，前后空格不计算为单词数
      value = value.replace(/\r|^\s+|\s+$/gi, "");
      // 多个空格替换成一个空格
      value = value.replace(/\s+/gi, " ");
      // 更新计数
      var length = 0;
      var match = value.match(/\s/g);
      if (match) {
        length = match.length + 1;
      } else if (value) {
        length = 1;
      }
      _this.wordCount = length
    },
    // 进入页面倒计时40秒开始录音
    daojishiBack() {
      let daojishiBo = setInterval(() => {
        if (_this.daojishiTime <= 0) {
          clearInterval(daojishiBo);
          _this.audio.src = require("../../assets/di.mp3");
          let playPromise;
          playPromise = _this.audio.play();
          _this.playStatus = true;
          if (playPromise) {
            playPromise
              .then(() => {
                // 音频加载成功
                // 音频的播放需要耗时
              })
              .catch((e) => {
                // 音频加载失败
                console.error(e);
              });
          }
          _this.getTime();
        } else {
          _this.daojishiTime--;
        }
      }, 1000);
    },

    // 返回上一页
    goBack() {
      _this.$router.back();
    },

    // 点击事件
    dianjiClick(item) {
      console.log(item);
      if(!item || item == '&nbsp;') {return;}
      this.$refs.word.word_text = item;
      this.$refs.word.getWord();
    },

    // 申请批改
    shenQingPiGai() {
      if (_this.huida) {
        var params = {
          topic_exercise_id: _this.topicExerciseId
        };
        _this.$axios
          .post("exercise.topic_exercise/submit", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.$message.success("您已提交申请");
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
      } else {
        _this.$message.error("您还没答题，请先进行答题");
      }
    },

    // 查看打分
    seeDaFen() {
      if (_this.huida) {
        _this.status = true;
      } else {
        _this.$message.error("您还没答题，请先进行答题");
      }
    },

    // 重做
    chongzuo() {
      _this.wordCount = 0;
      _this.time = 0;
      _this.content = "";
      _this.huida = false;
      _this.status = false;
      clearInterval(intervalTime);
      _this.daojishi();
    },
    copyDomain() {
      if (_this.topicExerciseId == null) {
        this.$message.error("请申请批改后复制链接");
        return
      }
      _this.$copyText(_this.$global.baseUrl + "&id=" + _this.topicExerciseId)
      this.$message.success("地址复制成功")
    },
    // 开始计时
    daojishi() {
      clearInterval(intervalTime);
      intervalTime = setInterval(() => {
        if (_this.timeLen > _this.time) {
          _this.time = _this.time + 1;

          _this.datiTime = _this.formateSeconds(_this.timeLen - _this.time);
        } else {
          clearInterval(intervalTime);
          _this.submitAns();
        }
      }, 1000);
    },

    //将秒转化为时分秒
    formateSeconds(endTime) {
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min > 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },

    // 点击完成
    submitAns() {
      var params = {
        topic_id: _this.id,
        content:  _this.content,
        time:     _this.time,
      };
      clearInterval(intervalTime);
      if (localStorage.getItem("token")) {
        _this.$axios
          .post("exercise.topic/submit", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.obj = res.data;
              _this.topicExerciseId = res.data.topicExerciseId
              // _this.obj.content = _this.obj.content.replaceAll('/\n/g', "<br/>");
              // _this.obj.content =  _this.obj.content.replaceAll(' ', "&nbsp;");
              console.log('当前要渲染的内容', _this.obj.content)
              _this.huida = true;
              _this.$message.success("答题已完成");
              _this.status = true;
              clearInterval(intervalTime);
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },

    // 获取详情
    getDetail() {
      var params = {
        topic_id: _this.id,
      };
      _this.$axios
        .post("exercise.topic/getTopicInfo", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.detail = res.data;
            _this.bofangStatus = false;
            _this.mouseStatus = false;
            _this.daojishi();
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 微信分享复制链接
    copyToH5(){

    // console.log('当前的domain', item);return
      let pinyin = _this.detail.topic_type_id == 6 ? 'WE' : 'SWT';
      let tips = pinyin+" "+_this.detail.number+" "+" PTE易王炸AI评分 ";
      this.$copyText(tips+" "+window.location.origin+"/H5Detail?exericise_id="+_this.topicExerciseId+"&topic_id="+ _this.detail.id+"&pinyin="+pinyin+"&title="+encodeURIComponent(_this.detail.name)+"&page=questionType6");
      this.$message.success("地址复制成功")
    },

    // 获取传递过来的参数
    getParams() {
      _this.id = _this.$route.query.id;
      _this.getDetail();
    },
  },
};
</script>


<style scoped>
.zuodacont {
  overflow: auto;
  word-wrap: break-word;
}

.topic_textarea_box {
  height: 400px !important;
}

.text-show {
  display: inline-block;
  width: 4px;

}
</style>
