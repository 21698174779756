<template>
    <div class="container">
        <headers></headers>
        <right></right>
        <scrollpage></scrollpage>

        <div class="w box_sizing width_box my_wrap">
            <div class="left_position">
                <left></left>
            </div>

            <div class="banner_detail box_sizing">
                <div class="mokaoP box_sizing">全项模考</div>
                <div class="flex-warp box_sizing flex-warp pics" @click="toMokaoList(1, '')">
                    <img src="../assets/images/mo1.png" alt="" class="pic">
                    <div class="pictext box_sizing">
                        <div class="pictitle">仿真模考</div>
                        <div class="pictip">Simulation test</div>
                    </div>
                </div>
                <div class="mokaoP box_sizing">专项模考</div>
                <div class="flex-warp box_sizing flex_box pics">
                  <div class="flex_box " style="position: relative;" @click="toMokaoList(4, '口语模考')">
                        <img src="../assets/images/mo2.png" alt="" class="pic">
                        <div class="pictext box_sizing" style="top: 0;">
                            <div class="pictitle">口语模考</div>
                            <div class="pictip">Speaking simulation test</div>
                        </div>
                    </div>
                     <div class="flex_box " style="position: relative;" @click="toMokaoList(5, '写作模考')">
                        <img src="../assets/images/mo2.png" alt="" class="pic">
                        <div class="pictext box_sizing" style="top: 0;">
                            <div class="pictitle">写作模考</div>
                            <div class="pictip">Writing simulation test</div>
                        </div>
                    </div>
                       <div class="flex_box " style="position: relative;" @click="toMokaoList(3, '阅读模考')">
                        <img src="../assets/images/mo2.png" alt="" class="pic">
                        <div class="pictext box_sizing" style="top: 0;">
                            <div class="pictitle">阅读模考</div>
                            <div class="pictip">Reading simulation test</div>
                        </div>
                    </div>
                    <div class="flex_box " style="position: relative;" @click="toMokaoList(2, '听力模考')">
                        <img src="../assets/images/mo2.png" alt="" class="pic">
                        <div class="pictext box_sizing" style="top: 0;">
                            <div class="pictitle">听力模考</div>
                            <div class="pictip">Listening simulation test</div>
                        </div>
                    </div>

                </div>

                <div class="mokaoP box_sizing">会员模考</div>
                <div class="flex-warp box_sizing flex-warp pics" @click="toMokaoList(6, '高频仿真模考')">
                    <img src="../assets/images/mo6.png" alt="" class="pic">
                    <div class="pictext box_sizing">
                        <div class="pictitle">高频仿真模考</div>
                        <div class="pictip" style="width: 123px;">High frequency simulation test</div>
                    </div>
                </div>
            </div>


        </div>


        <footers></footers>

    </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";

var _this;
export default {
  components: {
    headers,
    footers,
    left,
    right,
    scrollpage,
  },
  data() {
    return {
      mokao: [
        {name: "听力模考", tip: "Listening simulation test", image: require("../assets/images/mo2.png")},
        {name: "阅读模考", tip: "Reading simulation test", image: require("../assets/images/mo3.png")},
        {name: "口语模考", tip: "Speaking simulation test", image: require("../assets/images/mo4.png")},
        {name: "写作模考", tip: "Writing simulation test", image: require("../assets/images/mo5.png")}
      ],

    }
  },
  created() {
    _this = this;
  },
  mounted() {

  },
  methods: {
    toMokaoList(id, name) {
      if(!localStorage.getItem("token")) {
        this.$message.error("请登录后再进行答题");return
      } else {
        _this.$router.push({
          path: './mokaoList',
          query: {
            id: id,
            name: name
          }
        })
      }
    }
  }

}
</script>


<style>
    .banner_detail {
      width: 100%;
      padding: 34px 35px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 1);
    }

    .mokaoP {
      text-align: left;
      font-size: 20px;
      font-weight: bold;
      line-height: 44px;
      color: #06121E;
      padding: 19px 0;
      position: relative;
    }

    .mokaoP:after {
      content: '';
      width: 40px;
      height: 0px;
      border: 1px solid #B90038;
      opacity: 1;
      position: absolute;
      top: 100%;
      left: 0;
    }

    .pics {
      padding-top: 34px;
      position: relative;
    }

    .pic {
      width: 336px;
      height: 112px;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    .pictext {
      width: 336px;
      height: 112px;
      margin-right: 20px;
      margin-bottom: 20px;
      position: absolute;
      top: 34px;
      left: 0;
      color: #ffffff;
      padding: 20px 28px;
      text-align: left;
      cursor: pointer;
    }

    .pictitle {
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
      color: #FFFFFF;
    }

    .pictip {
      font-size: 16px;
      font-family: Poppins;
      font-weight: 500;
      color: #FFFFFF;
      margin-top: 11px;
    }
</style>
