//导出一个配置好的axios提供给main挂载

import axios from 'axios'
import Vue from 'vue'
import router from '@/router'
// import JSONBIG from 'json-bigint'

Vue.use(router)

console.log('process.env.VUE_APP_BASE_URL',process.env.VUE_APP_BASE_URL);
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.transformResponse = [(data) => {
    try {
        return parse(data)
    } catch (e) {
        return data
    }
}]

// 请求拦截器
axios.interceptors.request.use(config => {
    var token = localStorage.getItem('token')
    config.headers.token = token ? token : '';
    return config;
}, err => {
    return Promise.reject(err)
})
// 响应拦截器
axios.interceptors.response.use(res => {
    // console.log('111111')
    // console.log(res)
    var result = JSON.parse(res.data)
    if (result.code == 1) {
        return Promise.resolve(result);
    } else {
        return Promise.reject(result);
    }
}, err => {
    console.log(err);
    //   return Promise.reject(err)
})

export default axios



export function uploadFile(Url, data) {
    //上传图片的方法
    return new Promise((resolve, reject) => {
        // console.log('4444444444444444')
        let instance = axios.create({
            baseURL: 'http://yijishen.brofirst.cn/api/',
            method: 'POST',
            headers: {
                'mimeType': 'multipart/form-data',
                // ''
            },

        });
        instance.post(Url, data).then(res => {
            // console.log('2222222222222')
            resolve(res.data.data);
        }).catch(error => {
            // console.log('33333333333333')
            reject(error.data)
        })
    });
}




let imgurl = 'http://yijishen.brofirst.cn'
